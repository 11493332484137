// The original line was this, changed ThemeProvider to MuiThemeProvider

import { Theme, createMuiTheme } from "@material-ui/core";
import React, { memo } from 'react';
import { connect } from "react-redux";
import { Dimensions } from "../redux/reducers/ReducersGeneral";
import SyncActions from "../redux/actions/SyncActions";
import { ReduxStateType } from "../redux/reducers/Reducers";
import { ThemeProvider } from "@material-ui/styles";
import { DeviceKeys } from "../tools/Keys";
import { lightGreen, blueGrey } from "@material-ui/core/colors";

export interface CustomThemeProviderProps {

}

type UnitFunction = (param: number) => number;

export interface PlenikaTheme extends Theme {
    units: {
        h: UnitFunction;
        w: UnitFunction;
    },
    general: {
        isMobile: boolean;
    }
}

interface StateToPropsType {
    dimensions?: Dimensions;
    device: string;
}

interface DispatchToPropsType {
}

type Props = CustomThemeProviderProps & StateToPropsType & DispatchToPropsType;

const CustomThemeProvider: React.FC<Props> = (props) => {
    let heightUnit = 0;
    let widthUnit = 0;
    if (props.dimensions) {
        heightUnit = props.dimensions.height / 100;
        widthUnit = props.dimensions.width / 100;
    }

    const theme = {
        ...createMuiTheme({
            palette: {
                type: 'dark',
                primary: {
                    light: lightGreen[300],
                    main: lightGreen[500],
                    dark: lightGreen[700]
                },
                secondary: {
                    light: blueGrey[300],
                    main: blueGrey[500],
                    dark: blueGrey[700]
                },
            },
            typography:{
                fontSize: heightUnit * 1.5,
            },
        }),
        units: {
            h: (param) => heightUnit * param,
            w: (param) => widthUnit  * param,
        },
        general: {
            isMobile: props.device === DeviceKeys.MOBILE,
        },
    } as PlenikaTheme;
    return (
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
    if (!prevProps.dimensions) {
        return false;
    }
    return !!( nextProps.dimensions &&
        prevProps.dimensions.height === nextProps.dimensions.height &&
        prevProps.dimensions.width === nextProps.dimensions.width && 
        prevProps.device === nextProps.device
        )
}

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        dimensions: state.general.dimensions ? {...state.general.dimensions} : undefined,
        device: state.general.device,
    }
}

const mapDispatchToProps = { setSelectedChip: SyncActions.setSelectedChip };

export default connect<
StateToPropsType,
DispatchToPropsType,
CustomThemeProviderProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(memo<Props>(CustomThemeProvider, areEqual));
