import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { ReducersGeneralType } from '../../redux/reducers/ReducersGeneral';
import { ReducersGameType } from '../../redux/reducers/ReducersGame';
import { ReduxStateType } from '../../redux/reducers/Reducers';
import { connect } from 'react-redux';
import Timer from './Timer';
import { PlenikaTheme } from '../CustomThemeProvider';
import { GameStateKeys } from 'plenika-types';
import Tools from '../../tools/Tools';

const useStyles = makeStyles((theme: PlenikaTheme) => {
    const fontSize = theme.general.isMobile?6:2;
    return {
        statusBar: {
            height: '100%',
            display:'inline-block',
            color: '#f4f1e6',
            fontFamily: "'Open Sans', sans-serif",
            verticalAlign: 'center',
            fontSize: `${theme.units.h(fontSize)}px`, 
            lineHeight: `${theme.units.h(fontSize)}px`,
        },
        shadow: {
            backgroundColor: '#00000055',
            borderRadius: `${theme.units.h(fontSize/2)}px`, 
        },
        colorRed: {
            color: '#c3311c',
            ...(theme.general.isMobile ? {
                backgroundColor: '#0d010055',
                color: '#c3311c77',
                borderRadius: `${theme.units.h(3)}px!important`,
                marginTop:    `${theme.units.h(2)}px!important`,
                paddingTop:   `${theme.units.h(0.5)}px!important`,
                paddingBottom:`${theme.units.h(0.5)}px!important`,
                paddingLeft:  `${theme.units.h(2)}px!important`,
                paddingRight: `${theme.units.h(2)}px!important`,
            } : {}),
        },
        colorGreen: {
            color: '#1ba165',
            ...(theme.general.isMobile ? {
                backgroundColor: '#0d010055',
                color: '#1ba16577',
                borderRadius: `${theme.units.h(3)}px!important`,
                marginTop:    `${theme.units.h(2)}px!important`,
                paddingTop:   `${theme.units.h(0.5)}px!important`,
                paddingBottom:`${theme.units.h(0.5)}px!important`,
                paddingLeft:  `${theme.units.h(2)}px!important`,
                paddingRight: `${theme.units.h(2)}px!important`,
            } : {}),
        },
        colorBlack: {
            color: '#d2cfc5',
            ...(theme.general.isMobile ? {
                backgroundColor: '#d2cfc577',
                color: '#FFF',
                borderRadius: `${theme.units.h(3)}px!important`,
                marginTop:    `${theme.units.h(2)}px!important`,
                paddingTop:   `${theme.units.h(0.5)}px!important`,
                paddingBottom:`${theme.units.h(0.5)}px!important`,
                paddingLeft:  `${theme.units.h(2)}px!important`,
                paddingRight: `${theme.units.h(2)}px!important`,
            } : {}),
        },
        colorLucky: {
            color: '#e1c973',
        },
    };
});


export interface StatusBarProps {
}

interface StateToPropsType {
    game: ReducersGameType;
    general: ReducersGeneralType;
}

interface DispatchToPropsType {
}

type Props = StatusBarProps & StateToPropsType & DispatchToPropsType;

const StatusBar: React.FC<Props> = (props) => {
    const classes = useStyles();
    const toDisplay: string = props.game.round.current_state.display_name;
    const showColor = GameStateKeys.RESULTS === props.game.round.current_state.name; 
    const result = props.game.round.result;
    
    return (
        <>
            <div className={[classes.statusBar, (showColor && result) ? classes[Tools.getNumberColor(result.spinResult)] : ''].join(' ')}>
                {(result && props.game.round.current_state.name===GameStateKeys.RESULTS) 
                    ? result.spinResult : ''
                } {toDisplay}
            </div>
            <Timer />
        </>
    );
}

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        general: {...state.general},
        game: {...state.game},
    }
}

const mapDispatchToProps = { };

export default connect<
StateToPropsType,
DispatchToPropsType,
StatusBarProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(StatusBar);
