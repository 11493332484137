import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { ReducersGeneralType } from '../redux/reducers/ReducersGeneral';
import { ReduxStateType } from '../redux/reducers/Reducers';
import { connect } from 'react-redux';
import SideButton from './SideButton';
import iconStaticstics from '../assests/icon-statistics.png';
import iconFavStar from '../assests/icon-fav.svg';
import iconMenu from '../assests/icon-menu.svg';
import iconRacetrack from '../assests/icon-racetrack.svg';


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: '100%', 
        width: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        // TODO change it to ratio
    },
    marginTop: {
        marginTop: '100%',
    },
    marginBottom: {
        marginBottom: '100%',
    },
    flexAuto: {
        flex: 'auto',
    },
}));


export interface SideMenuProps {
}

interface StateToPropsType {
    general: ReducersGeneralType;
}

interface DispatchToPropsType {
}

type Props = SideMenuProps & StateToPropsType & DispatchToPropsType;

const SideMenu: React.FC<Props> = (props) => {
    const classes = useStyles();

    let buttonSize = 50;
    if (props.general.dimensions && props.general.dimensions.width) {
        buttonSize = props.general.dimensions.width/20;
    }

    return (
        <div className={classes.root}>
                <div className={classes.marginTop}>
                    <SideButton size={buttonSize} src={iconRacetrack}/>
                </div>

                <div className={classes.marginTop}>
                    <SideButton size={buttonSize} src={iconFavStar}/>
                </div>

                <div className={classes.marginTop}>
                    <SideButton size={buttonSize} src={iconStaticstics}/>
                </div>

                <div className={classes.flexAuto}/>
                
                <div className={classes.marginBottom}>
                    <SideButton size={buttonSize} src={iconMenu} />
                </div>
        </div>
    );
} 

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        general: {...state.general},
    }
}

const mapDispatchToProps = { };

export default connect<
StateToPropsType,
DispatchToPropsType,
SideMenuProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(SideMenu);
