import { ActionKeys, ActionStatusKeys, DeviceKeys } from '../../tools/Keys';
import { IUser, ILoginResponse } from 'plenika-types';
import Tools from '../../tools/Tools';

export interface Dimensions {
    width: number,
    height: number,
}

export interface ReducersGeneralType {
    device: string,
    dimensions?: Dimensions,
    screenDimensions?: Dimensions,
    sideBarWidth?: number,
    postSetup: boolean,
    fullScreen: boolean,
    token?: string,
}

function getInitialState(): ReducersGeneralType {
    return {
        device: DeviceKeys.DESKTOP,
        postSetup: false,
        fullScreen: false,
    };
}

export default function (state = getInitialState(), action: any): ReducersGeneralType {
    switch (action.type) {
        case ActionKeys.UPDATE_DEVICE:
            return {
                ...state,
                device: action.value,
            };
        case ActionKeys.UPDATE_DIMENSIONS:
            const updateState: Partial<ReducersGeneralType> = {};
            if (action.dimensions) {
                updateState.dimensions = action.dimensions;
            }
            if (action.screenDimensions) {
                updateState.screenDimensions = action.screenDimensions;
            }
            if (action.sideBarWidth != null) {
                updateState.sideBarWidth = action.sideBarWidth;
            }
            return {
                ...state,
                ...updateState,
            };
        case ActionKeys.SET_TOKEN:
            return {
                ...state,
                token: action.value,
            };
        // case ActionKeys.INTIATE_USER:
            
        //     if (action.status === ActionStatusKeys.SUCCESS) {
                
        //         const response = action.response as ILoginResponse;
        //         return {
        //             ...state,
        //             user: response.user,
        //         }
        //     }
        //     break;

        case ActionKeys.SET_SETUP:
            return {
                ...state,
                postSetup: action.value,
            };
        case ActionKeys.SET_FULL_SCREEN:
            if (action.value === state.fullScreen) return state;
            Tools.setFullscreen(action.value);
            return {
                ...state,
                fullScreen: action.value,
            };
    }

    return state;
}
