import React, { useState } from 'react';
import { makeStyles, CSSProperties } from '@material-ui/styles';
import { ReducersGameType } from '../../redux/reducers/ReducersGame';
import { ReducersGeneralType } from '../../redux/reducers/ReducersGeneral';
import { ReduxStateType } from '../../redux/reducers/Reducers';
import { connect } from 'react-redux';
import SyncActions from '../../redux/actions/SyncActions';
import { PlenikaTheme } from '../CustomThemeProvider';
import undoIcon from '../../assests/undo-3.png';
import { DeviceKeys } from '../../tools/Keys';
import GridButton from './GridButton';

const useStyles = makeStyles((theme: PlenikaTheme) => ({
}));

export interface UndoButtonProps {
};


interface StateToPropsType {
    general: ReducersGeneralType;
    game: ReducersGameType;
}

interface DispatchToPropsType {
    undoBet: typeof SyncActions.undoBet;
}

type Props = UndoButtonProps & StateToPropsType & DispatchToPropsType;
  
const UndoButton: React.FC<Props> = (props) => {
    const classes = useStyles();
    
    const disable = !(props.game.betActions.filter(r=>r.active).length>0);
    return (
        <GridButton
            disable={disable}
            rightIcon={undoIcon}
            onClick={() => props.undoBet()}
            text={"UNDO"}
        />
    );
}

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        general: {...state.general},
        game: {...state.game},
    }
}

const mapDispatchToProps = { undoBet: SyncActions.undoBet };

export default connect<
StateToPropsType,
DispatchToPropsType,
UndoButtonProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(UndoButton);
