import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { PlenikaTheme } from './CustomThemeProvider';
import touchIcon from '../assests/tap.svg';
import scrollIcon from '../assests/zoom-in.svg';
import RoateScreenSRC from '../assests/rotate_device.png';
import Tools from '../tools/Tools';
import { ReducersGeneralType } from '../redux/reducers/ReducersGeneral';
import { ReduxStateType } from '../redux/reducers/Reducers';
import { connect } from 'react-redux';
import SyncActions from '../redux/actions/SyncActions';


export interface SwipeRouchProps {

}

interface StateToPropsType {
    general: ReducersGeneralType;
}

interface DispatchToPropsType {
    setFullScreen: typeof SyncActions.setFullScreen,
}


const useStyles = makeStyles((theme: PlenikaTheme) => ({
    root: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundImage: 'linear-gradient(115deg, #4e493a 0%, #36373c 25%, #2c2d2f 75%, #391515 99%);',
    },
    subRoot: {
        width: '100%',
        height: '101vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    landscapeIcon: {
        maxWidth: '50%', 
        width: '50%',
        maxHeight: '50%',
        filter: 'brightness(0) invert(1)',
    },
    innerContainer: {
        textAlign: 'center',
        color: 'white',
    }
}));

type Props = SwipeRouchProps & StateToPropsType & DispatchToPropsType;

const RotateScreen: React.FC<Props> = (props) => {
    const classes = useStyles();
    const divRef = useRef(null);
    //@ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const andriod = /android/i.test(userAgent);
    const handleScrolling = () => {
        andriod && dispatch();
    };
    const handleClick = () => {
        (!andriod) && dispatch();
    };
    const dispatch = () => {
        props.setFullScreen(true);
    };
    return (
        <div className={classes.root} 
            onScroll={handleScrolling}
            onClick={handleClick}
            ref={divRef}
            style={andriod ? {overflowY: 'scroll'} : undefined}
            >
            <div className={classes.subRoot}>
                <div className={classes.innerContainer}>
                    {
                        andriod ? (
                            <img
                                className={classes.landscapeIcon}
                                src={scrollIcon}
                            />
                        ) : (
                            <img
                                className={classes.landscapeIcon}
                                src={touchIcon}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
} 

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        general: {...state.general},
    }
}

const mapDispatchToProps = { 
    setFullScreen: SyncActions.setFullScreen,
};

export default connect<
StateToPropsType,
DispatchToPropsType,
SwipeRouchProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(RotateScreen);