import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { PlenikaTheme } from '../CustomThemeProvider';

const useStyles = makeStyles((theme: PlenikaTheme) => {
    const size = theme.general.isMobile ? 12 : 6.5;
    return {
        frame: {
            height: theme.units.h(size),
            display: 'inline-block',
            '&:hover': {
                borderRadius: theme.units.h(size/2),
                backgroundColor: '#FFFFFF55',
            },
            paddingLeft: theme.units.w(0.5),
            paddingRight: theme.units.w(0.5),
        },
        frameDisable: {
            opacity: '0.2',
            pointerEvents: 'none',
        },
        text: {
            display: 'inline-block',
            fontFamily: "'Open Sans', sans-serif",
            fontSize: theme.units.h(size/3),
            lineHeight: `${theme.units.h(size)}px`,
            color: 'white',
            verticalAlign: 'top',
        },
        icon: {
            display: 'inline-block',
            height: theme.units.h(size),
            width: theme.units.h(size),
            maxWidth: theme.units.h(size),
            maxHeight: theme.units.h(size),
            verticalAlign: 'top',
        },
        img: {
            width: '100%',
            height: '100%',
            maxHeight: '100%',
            maxWidth: '100%',
        }
    };
});

export interface GridButtonProps {
    onClick?: Function;
    leftIcon?: any;
    rightIcon?: any;
    disable?: boolean;
    text?: string;
};

type Props = GridButtonProps;
  
const GridButton: React.FC<Props> = (props) => {
    const classes = useStyles();
    return (
        <div 
            className={[classes.frame, props.disable ? classes.frameDisable : '', ].join(' ')} 
            onClick={() => props.onClick && props.onClick()} 
        >
            { props.leftIcon && 
                <div className={classes.icon}>
                    <img className={classes.img} src={props.leftIcon}/>
                </div>
            }
            <div className={[classes.text].join(' ')}>
                <div>{props.text}</div>
            </div>
            { props.rightIcon &&
                <div className={classes.icon}>
                    <img className={classes.img} src={props.rightIcon}/>
                </div>
            }
        </div>
    );
}

export default GridButton;
