import React from 'react';
import Tools from '../../tools/Tools';
import { makeStyles } from '@material-ui/styles';
import { PlenikaTheme } from '../CustomThemeProvider';

const useStyles = makeStyles((theme: PlenikaTheme) => {
    const fonstSize = theme.general.isMobile ? 3.5 : 2;
    return {
        container: {
            position: 'relative',
            textAlign: 'center',
            color: 'white',
            width: '100%', 
            height: '100%',
            fontSize: '75%',
            fontFamily: "'Alatsi', sans-serif",
        },
        center: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            lineHeight: `${theme.units.h(fonstSize)}px`,
            fontSize: theme.units.h(fonstSize), 
            fontFamily: 'open sans',
            fontWeight: 600,
            fontStretch: 'normal',
        },
        imgMax: {
            maxHeight: '100%', 
            maxWidth: '100%',
        }
    };
});

export interface ChipProps {
    amount: number;
}

type Props = ChipProps;

const Chip: React.FC<Props> = (props) => {
    const classes = useStyles();
    // Get the src of the right chip
    const src = Tools.getChipSrc(props.amount);

    return (
        <div className={classes.container}>
            <img className={classes.imgMax} src={src}/>
            <div className={classes.center}>
                {props.amount/100}
            </div>
        </div>
    );
} 

export default Chip;
