import React from 'react';
import { makeStyles, CSSProperties } from '@material-ui/styles';
import { PlenikaTheme } from '../CustomThemeProvider';
import { ReduxStateType } from '../../redux/reducers/Reducers';
import { connect } from 'react-redux';
import { ReducersGameType } from '../../redux/reducers/ReducersGame';
import { Colors } from '../hooks/UseGradient';
import { GameStateKeys } from 'plenika-types';

import square_1 from '../../assests/squares/square_1.png';
import square_2 from '../../assests/squares/square_2.png';
import square_3 from '../../assests/squares/square_3.png';
import square_4 from '../../assests/squares/square_4.png';
import square_5 from '../../assests/squares/square_5.png';
import Tools from '../../tools/Tools';

export interface LuckyNumbersRouletteProps {
}

interface StateToPropsType {
    game: ReducersGameType;
}

interface DispatchToPropsType {
}

const useStyles = makeStyles((theme: PlenikaTheme) => {
    const itemSize = 10;
    const itemFrameStyle = {
        width: theme.units.h(12), 
        height: theme.units.h(12), 
        backgroundSize: 'cover', 
        backgroundPosition: 'center', 
        backgroundRepeat: 'no-repeat',
        position: 'relative',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    } as CSSProperties;
    return {
        supremeFrame: {
            marginRight: theme.units.w(1),

            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        item: {
            width: theme.units.h(itemSize),
            height: theme.units.h(itemSize),
            fontFamily: `'Alatsi', sans-serif`,
            fontSize: theme.units.h(itemSize/2),
            lineHeight: `${theme.units.h(itemSize)}px`,
            margin: theme.units.h(2),
            textAlign: 'center',
        },
        colorRed: {
            color: '#FFF',
            backgroundColor: '#f63745',
        },
        colorBlack: {
            color: '#FFF',
            backgroundColor: '#0d0100',
        },
        colorGreen: {
            color: '#FFF',
            backgroundColor: Colors.GREEN,
        },
        colorLucky: {
            // Just for the usr of getNumberColor
        },
        itemFrame1: {
            background: `url(${square_1})`,
            ...itemFrameStyle,
        },
        itemFrame2: {
            background: `url(${square_2})`,
            ...itemFrameStyle,
        },
        itemFrame3: {
            background: `url(${square_3})`,
            ...itemFrameStyle,
        },
        itemFrame4: {
            background: `url(${square_4})`,
            ...itemFrameStyle,
        },
        itemFrame5: {
            background: `url(${square_5})`,
            ...itemFrameStyle,
        },
        titleFrame: {
            display: 'inline-block',
            fontSize: `${theme.units.h(5)}px`,
            color: '#e1c973',
        },
        subTitleFrame: {
            position: 'absolute',
            paddingBottom: 2,
            bottom: 0,
            left: 0,
            width: '100%',
            fontSize: `${theme.units.h(1.5)}px`,
            color: '#e1c973',
            textAlign: 'center',
        },
        
    };
});

type Props = LuckyNumbersRouletteProps & StateToPropsType & DispatchToPropsType;

const LuckyNumbersRoulette: React.FC<Props> = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.supremeFrame}>
            {
                /*props.game.luckyNumberBundle.luckyNumbers && 
                props.game.luckyNumberBundle.luckyNumbers.map(lNumber => (
                    <LuckyNumber number={lNumber}/>
                ))*/
                /*<>
                <LuckyNumber number={1} show={show} id={0}/>
                <LuckyNumber number={2} show={show} id={1}/>
                <LuckyNumber number={3} show={show} id={2}/>
                <LuckyNumber number={4} show={show} id={3}/>
                </>*/
                <>
                    {
                        // props.game.round.current_state.name === "game_round" &&
                        props.game.round.result &&
                        props.game.round.result.luckyNumbers &&
                        props.game.round.result.multiplication &&
                        props.game.round.result.luckyNumbers.map((luckyNumber, i) => {
                            // @ts-ignore
                            const className = classes[`itemFrame${i+1}`] as string;
                            return (
                                <div className={className} key={i}>
                                    <div className={classes.titleFrame}>
                                        {luckyNumber}
                                    </div>
                                    <div className={classes.subTitleFrame}>
                                        {
                                            // @ts-ignore
                                            props.game.round.result.multiplication[i]
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                </>
            }
        </div>
    );
} 

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        game: {...state.game},
    }
}

const mapDispatchToProps = { 
};

export default connect<
StateToPropsType,
DispatchToPropsType,
LuckyNumbersRouletteProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(LuckyNumbersRoulette);