import React, { useEffect, useRef, memo } from 'react';
import { makeStyles, CSSProperties } from '@material-ui/styles';
import { PlenikaTheme } from '../CustomThemeProvider';
import { connect, useSelector } from 'react-redux';
import { ReduxStateType } from '../../redux/reducers/Reducers';
import Tools from '../../tools/Tools';
import useGradient, {generateGradient} from '../hooks/UseGradient';

const useStyles = makeStyles<PlenikaTheme, any>((theme) => {
    // TODO remove this
    const gradient = generateGradient(['stopColor'], 'borderAnim');
    return {
        border: {
            strokeWidth: 5,
            stroke: 'url(#gridBorderGradient)',
            strokeOpacity: 1,
            fill: 'none',
        },
        ...gradient,
    };
});



interface StateToPropsType {
    canBet: boolean;
    startTime: number;
}

interface DispatchToPropsType {
}

export interface BetBorderProps {
}

type Props = BetBorderProps & StateToPropsType & DispatchToPropsType;

const WIDTH = 1000;
const HEIGHT = 455;

const drawBorderPath = (w: number, h: number) => {
    const cruveSize = w/40;
    return `
        M ${cruveSize},0 
        L ${w-cruveSize},0 
        Q ${w},0         ${w},${cruveSize} 
        L ${w},${h-cruveSize} 
        Q ${w},${h}   ${w-cruveSize},${h} 
        L ${cruveSize},${h} 
        Q 0,${h}         0,${h-cruveSize} 
        L 0,${cruveSize} 
        Q 0,0               ${cruveSize},0 
        Z
    `;
}

const BetBorder: React.FC<Props> = (props) => {
    const gradientValues = useGradient();
    const classes = useStyles();

    const width = WIDTH;
    const height = props.canBet ? HEIGHT : HEIGHT*0.8;
    
    return (
        <svg
            viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
            version="1.1"
            id="gridBorder"
        >
            <defs id="gridBorderDefs">
                <linearGradient 
                    id="gridBorderGradient" 
                    gradientTransform="rotate(90)" 
                    gradientUnits="userSpaceOnUse">
                    
                    <stop 
                        offset="0" 
                        stopColor="#fff" 
                        className={props.canBet ? classes.gradientAnim : ''}
                        style={gradientValues.style}
                    />

                    <stop 
                        offset="0.3" 
                        stopColor="#fff"
                    />
                
                </linearGradient>
            </defs>
            <g id="geidBorderLayer">
                <path
                    id="path"
                    d={drawBorderPath(width, height)}
                    className={[classes.border].join(' ')}
                />
                
            </g>
        </svg>
    );
} 

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        canBet: state.game.round.current_state.can_bet,
        startTime: state.game.round.time_started_unix,
    }
}

const mapDispatchToProps = {  };

export default connect<
StateToPropsType,
DispatchToPropsType,
BetBorderProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(BetBorder);