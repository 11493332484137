"use strict";

var _classCallCheck = require("/opt/plenika/plenika_new/plenika-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var HttpException = function HttpException(status, message) {
  _classCallCheck(this, HttpException);

  this.status = status || null;
  this.message = message;
  this.success = false;
};

exports.default = HttpException;