import blue from    '../assests/chips/blue.png';
import brown from   '../assests/chips/brown.png';
import cyan from    '../assests/chips/cyan.png';
import green from   '../assests/chips/green.png';
import orange from  '../assests/chips/orange.png';
import red from     '../assests/chips/red.png';
import violet from  '../assests/chips/violet.png';
import { BetAction } from '../redux/reducers/ReducersGame';
import { RoundBet, getTotalBets, Bet } from 'plenika-types';
import { maxForLocation, redNumbers, blackNumbers } from 'plenika-types/dist/modules/bet/Bet';

const string: (value: any) => string = (value) => {
    if (value === null || value === undefined) {
        return "";
    }
    return String(value);
}

const isMobile: () => boolean = () => {
    return (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i
            .test(navigator.userAgent)
        ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i
            .test(navigator.userAgent.substr(0,4))
    );
}

const devMod = () => {
    return false;
}

const getBaseUrl = () => {
    //'http://192.168.1.104:9098/'
    return devMod() ? 'http://localhost:9098/' : 'https://plenikaserver.adiluz.com/';
}

const getChipSrc = (amount: number) => {
    // Get the src of the right chip
    return srcTable.reduce(
        (current, value) => current.amount < value.amount && value.amount <= amount ? value : current
        // Get the smallest chip as a default
        ,srcTable.reduce(
            (current, value) => current.amount < value.amount ? current : value
        )
    ).src;
}

const getChipNumbers = () => {
    return [
        20,
        50,
        100,
        500,
        2500,
        10000,
        50000,
    ];
}

const srcTable: {src: string, amount: number}[] = [
    {src: orange, amount: getChipNumbers()[0]},
    {src: blue,   amount: getChipNumbers()[1]},
    {src: violet, amount: getChipNumbers()[2]},
    {src: green,  amount: getChipNumbers()[3]},
    {src: brown,  amount: getChipNumbers()[4]},
    {src: cyan,   amount: getChipNumbers()[5]},
    {src: red,    amount: getChipNumbers()[6]},
];

const reduceBetActions = (actions: BetAction[]) => {
    return actions.filter(action=>action.active).reduce((sum, item) => [...sum, ...item.bets], [] as RoundBet[]);
};

const reduceByLocation: (bets: RoundBet[]) => RoundBet[] = (bets) => {
    const map: {[key: string]: number} = {};
    for (const bet of bets) {
        const amount = map[bet.betId] || 0;
        map[bet.betId] = amount + bet.betAmount;
    }
    return Object.keys(map).map(betId => ({
        betId: betId,
        betAmount: map[betId],
    }))
}

// Full-Screen [Start]
const element = document.documentElement;
const setFullscreen = (fullScreen?: boolean) => {
    //@ts-ignore
    const isFullScreen = document[getFullscreenKey()] != null;
    const openFullScreen = fullScreen !== undefined ? fullScreen : !isFullScreen;
    if (openFullScreen) {
        openFullScreenDelay();
    } else {
        closeFullScreenDelay();
    }
};
/**
 * These function will sleep
 */
const openFullScreenDelay = async () => {
    await sleep(0);
    //@ts-ignore
    element[openFullScreenKey()]().catch(e=>console.log('open full screen error : ', e));
}
/**
 * These function will sleep
 */
const closeFullScreenDelay = async () => {
    await sleep(0);
    //@ts-ignore
    document[closeFullScreenKey()]().catch(e=>console.log('open full screen error : ', e));
}
const openFullScreenKey = () => {
    const functionsKeys = [
        'requestFullscreen',
        'mozRequestFullScreen',
        'webkitRequestFullscreen',
        'msRequestFullscreen',
    ];
    // @ts-ignore
    return functionsKeys.find(key => element[key]) || "";
}
const closeFullScreenKeys = () => {
    const functionsKeys = [
        'exitFullscreen',
        'mozCancelFullScreen',
        'webkitExitFullscreen',
        'msExitFullscreen',
    ];
    // @ts-ignore
    return functionsKeys.find(key => element[key]) || "";
}
const getFullscreenKey = () => {
    const fullscreenKeys = [
        "fullscreenElement",
        "mozFullScreenElement",
        "msFullscreenElement",
        "webkitFullscreenElement",
    ];
    // @ts-ignore
    return fullscreenKeys.find(key => typeof document[key] !== "undefined");
}
// Full-Screen [End]

const sleep = (time: number) => new Promise(r => setTimeout(r, time));

const printFunds = (amount: number) => {
    return (amount/100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

const clearFunds = (funds: string) => {
    const index = funds.indexOf('.');
    if (index === -1 || !/^0*$/.test(funds.substr(index+1))) {
        return funds;
    }
    return funds.substr(0, index);
}

const secondsDifference = (startTime: number) => {
    return ((new Date()).getTime() - startTime) / 1000;
}

interface ValidBetReport {
    valid: boolean; 
    message: string;
    add?: RoundBet;
};
type isAddBetsValidType = (
    currentActions: BetAction[], 
    requestedToAdd: RoundBet[], 
    userBalance: number,
) => ValidBetReport[];
const addBetsValidReports: isAddBetsValidType = (currentActions, requestedToAdd, userBalance) => {
    // TODO reduce all bets to locaitons 
    requestedToAdd = reduceByLocation(requestedToAdd);

    const reports: ValidBetReport[] = [];

    for (const requestedBet of requestedToAdd) {
        // Start Initial Calculeting
        const currentSum  = getTotalBets(currentActions, requestedBet.betId);
        const expectedSum = currentSum + requestedBet.betAmount;
        const bet = new Bet({
            name: requestedBet.betId,
            amount: expectedSum,
            balance: userBalance,
        });
        // End Initial Calculeting
        
        // Start Check Impossibility  
        if (!(bet.isValid() || bet.continue_to_limit)) {
            reports.push({
                message: bet.valid_message,
                valid: false,
            });
            continue;
        }
        // End Check Impossibility

        // Start Final Analysis
        let report = {
            message: '',
            valid: true,
            add: {...requestedBet},
        };
        
        const differences = [
            expectedSum - bet.type.max,
            expectedSum - maxForLocation,
            requestedBet.betAmount - userBalance,
        ];
        const maxDifference = Math.max(...differences);
        if (maxDifference > 0) {
            // take it to the limit
            report.add.betAmount = requestedBet.betAmount - maxDifference;
            report.message = bet.valid_message;
            report.valid = false;
        }
        // done
        userBalance -= report.add.betAmount; 
        reports.push(report);
        // End Final Analysis
    }
    return reports;
};

const isReportsCanAdd = (reports: ValidBetReport[]) => {
    return reports.filter(r=>r.add).length > 0;
}

const getNumberColor = (number: number, lucky?: boolean) => {
    if (lucky) {
        return 'colorLucky';
    }
    if (redNumbers.includes(number)) {
        return 'colorRed';
    }
    if (blackNumbers.includes(number)) {
        return 'colorBlack';
    }
    return 'colorGreen';
}

export default  {
    string: string,
    isMobile: isMobile,
    getBaseUrl: getBaseUrl,
    getChipSrc: getChipSrc,
    getChipNumbers: getChipNumbers,
    reduceBetActions: reduceBetActions,
    addBetsValidReports: addBetsValidReports,
    isReportsCanAdd: isReportsCanAdd,
    setFullscreen: setFullscreen,
    printFunds: printFunds,
    clearFunds: clearFunds,
    secondsDifference: secondsDifference,
    getNumberColor: getNumberColor,
}
