import React from 'react';
import { makeStyles, CSSProperties } from "@material-ui/styles";
import { PlenikaTheme } from "../CustomThemeProvider";
import SyncActions from '../../redux/actions/SyncActions';
import { ReduxStateType } from '../../redux/reducers/Reducers';
import { ReducersGameType } from '../../redux/reducers/ReducersGame';
import xIcon from '../../assests/icon-x.png';
import { connect } from 'react-redux';
import { BetData } from 'plenika-types';
import Tools from '../../tools/Tools';
import { PopupKeys } from '../../tools/Keys';

export interface DesktopTablePopupProps {
}

interface StateToPropsType {
    game: ReducersGameType;
}

interface DispatchToPropsType {
    setPopupFlag: typeof SyncActions.setPopupFlag;
}

type Props = DesktopTablePopupProps & DispatchToPropsType & StateToPropsType;

const useStyles = makeStyles((theme: PlenikaTheme) => {
    const width = 28;
    const height = 35.5;
    const fontSize = 1.7;
    const mobileFontSize = 3.5;

    const mobileWidth = 100;
    const mobileTop = 13;

    return {
        frame: {
            position: 'absolute',
            top: theme.units.h(2.4),
            left: `-100vw`,
            color: 'white',
            paddingLeft: theme.units.h(2),
            paddingRight: theme.units.h(2),
            zIndex: 2600,

            transition: '1s',

            width: theme.units.w(width),
            height: theme.units.h(height),
            
            background: '#0d010066', //'linear-gradient(90deg, #363232AA 0%, #141212AA 100%)',
            borderRadius: theme.units.h(0.5),

            fontSize: `${theme.units.h(fontSize)}px`,

            ...( theme.general.isMobile ? {
                width: theme.units.w(mobileWidth),
                height: theme.units.h(100-mobileTop),
                top: theme.units.h(mobileTop),
                backgroundImage: 'linear-gradient(113deg, #4e493a 0%, #36373c 25%, #2c2d2f 76%, #391515 99%)',
                paddingLeft:  0,
                paddingRight: 0,
                fontSize: `${theme.units.h(mobileFontSize)}px`,
            } : {}),
        },
        show: {
            left: 0,
        },
        textFrame: {
            color: 'white',
        },
        rowFrame: {
            paddingLeft: theme.units.w(1),
            paddingRight: theme.units.w(1),
            marginTop: theme.units.h(0.5),
            marginBottom: theme.units.h(0.5),
            display: 'flex',
            paddingTop: theme.units.h(0.1),
            paddingBottom: theme.units.h(0.1),

            ...(theme.general.isMobile ? {
                marginLeft: theme.units.w(8),
                marginRight: theme.units.w(8),
                paddingTop: theme.units.h(0),
                paddingBottom: theme.units.h(0),
            } : {})
        },
        itemFrame: {
            flex: 1,
        },
        grandBackground: {
            backgroundImage: 'linear-gradient(to right, #eeeeee44 0%, #b8b8ce44 100%)',
            borderRadius: theme.units.h(fontSize/2),
        },
        headerFrame: {
            width: '100%',
            height: theme.units.h(8), 
            lineHeight: `${theme.units.h(8)}px`,
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
            backgroundImage: 'linear-gradient(to right, #eeeeee33 0%, #b8b8ce33 100%)',
            textAlign: 'center',
            display: theme.general.isMobile ? 'block' : 'none',
            color: '#d2cfc5',
        },
        marginHeight: {
            height: theme.units.h(4),
            display: theme.general.isMobile ? 'block' : 'none',
        },
        bottomBar: {
            textAlign: 'center',
            display: theme.general.isMobile ? 'block' : 'none',
        },
        yellow: {
            color: '#fff3c2', 
            display: 'inline', 
        },
        closeButton: {
            position: 'absolute', 
            margin: theme.units.h(1),
            width:  theme.units.h(5), 
            height: theme.units.h(5), 
            top: 0,
            right: 0,
            display: 'none',
            ...(theme.general.isMobile ? {
                display: 'block',
            } : {}),
        },
        closeIcon: {
            maxWidth: '100%',
            maxHeight: '100%',
        },
        additonalMargin: {
            ...(theme.general.isMobile ? {} : {
                marginTop: theme.units.h(2),
            })
        },
        backgroundFrame: {
            width: '100%', 
            height: '100%', 
            zIndex: 2500, 
            // pointerEvents: 'none', 
            position: 'absolute', 
            top: 0, 
            left: 0,
        }
    };
});

const sortedBetData = [
    BetData.straight,
    BetData.split,
    BetData.street,
    BetData.corner,
    BetData.line,
    BetData.column,
    BetData.dozen,
    BetData.red_black,
    BetData.even_odd,
    BetData.one_eighteen,
];

const DesktopTablePopup: React.FC<Props> = (props) => {
    const classes = useStyles();
    const open = props.game.popupsState[PopupKeys.TABLE];
    const setPopupHandle = (value: boolean) => {
        props.setPopupFlag(PopupKeys.TABLE, value);
    };
    return (
        <>
            {open && <div 
                onMouseOver={()=>setPopupHandle(false)} 
                className={classes.backgroundFrame}
            />}
            <div 
                className={[classes.frame, open ? classes.show : '', ].join(' ')}>
                <div 
                    className={classes.closeButton} >
                    <img src={xIcon} className={classes.closeIcon}/>
                </div>
        
                <div className={classes.headerFrame}>
                        {'Limits & payouts'}
                </div>
                <div className={classes.marginHeight}/>
        
                <div className={[classes.rowFrame, classes.grandBackground, classes.additonalMargin].join(' ')}>
                    <div className={classes.itemFrame}>
                        BET TYPE
                    </div>
                    <div className={classes.itemFrame}>
                        MIN
                    </div>
                    <div className={classes.itemFrame}>
                        MAX
                    </div>
                    <div className={classes.itemFrame}>
                        PAYOUT
                    </div>
                </div>
                {
                    sortedBetData.map((betData, i) => (
                        <div className={classes.rowFrame} key={i}>
                            <div className={classes.itemFrame}>
                                {betData.display_name}
                            </div>
                            <div className={classes.itemFrame}>
                                {Tools.clearFunds(Tools.printFunds(betData.min))}
                            </div>
                            <div className={classes.itemFrame}>
                                {Tools.clearFunds(Tools.printFunds(betData.max))}
                            </div>
                            <div className={classes.itemFrame}>
                                {`${betData.payout===29?'29-499':betData.payout}:1`}
                            </div>
                        </div>
                    ))
                }
                <div className={classes.marginHeight}/>
                <div className={classes.bottomBar}>
                    Spark Roulette bet limits <div className={classes.yellow}>$0.20 - 1,000</div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        game: {...state.game},
    }
}

const mapDispatchToProps = { 
    setPopupFlag:      SyncActions.setPopupFlag,
};

export default connect<
StateToPropsType,
DispatchToPropsType,
DesktopTablePopupProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(DesktopTablePopup);