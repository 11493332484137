import React from 'react';
import logo from './resources/logo.svg';
import './App.css';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import Reducers from './redux/reducers/Reducers';
import {composeWithDevTools} from "redux-devtools-extension";
import thunkMiddleware from 'redux-thunk';
import { Route, Switch } from 'react-router';
import InitinalPage from './pages/InitinalPage';
import GamePage from './pages/GamePage';
import { BrowserRouter } from 'react-router-dom';
import Redirect from './components/Redirect';
import CustomThemeProvider from './components/CustomThemeProvider';
import SocketServiceComp from './components/SocketServiceComp';

const store = createStore(
    Reducers,
    composeWithDevTools(
        applyMiddleware(
            thunkMiddleware,
            // loggerMiddleware,
        ))
);

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <CustomThemeProvider>
                <SocketServiceComp/>
                <BrowserRouter>
                    <Redirect/>
                        <Switch>
                            <Route path="/play">
                                <GamePage />
                            </Route>
                            <Route path="/">
                                <InitinalPage />
                            </Route>
                            <Route path="/:id">
                                <InitinalPage />
                            </Route>
                        </Switch>
                  </BrowserRouter>
            </CustomThemeProvider>
        </Provider>
    );
}

export default App;
