import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { PlenikaTheme } from '../CustomThemeProvider';
import SyncActions from '../../redux/actions/SyncActions';
import AsyncActions from '../../redux/actions/AsyncActions';
import { ReduxStateType } from '../../redux/reducers/Reducers';
import { ReducersGameType } from '../../redux/reducers/ReducersGame';
import xIcon from '../../assests/icon-x.png';
import { PopupKeys } from '../../tools/Keys';
import { TextField, Button } from '@material-ui/core';
import socketService from '../../services/SocketService';
import { IUser } from 'plenika-types';

export interface MessageDialogProps {
}

interface StateToPropsType {
    game: ReducersGameType;
}

interface DispatchToPropsType {
    setPopupFlag: typeof SyncActions.setPopupFlag;
    setPopupMessage: typeof SyncActions.setPopupMessage;
    resetBalance:  Function;
}

type Props = MessageDialogProps & StateToPropsType & DispatchToPropsType;

const useStyles = makeStyles((theme: PlenikaTheme) => {
    const width = theme.general.isMobile ? 43 : 31.5;
    const height = theme.general.isMobile ? 41.6 : 27.7;
    return {
        ultraFrameBalance: {
            position: 'absolute', 
            top: 0, 
            left: 0, 
            display: 'flex', 
            width: '100%', 
            height: '100%', 
            alignItems: 'center', 
            justifyContent: 'center',
            zIndex: 5000,
            opacity: 0.7,
            backgroundColor: '#000'             
        },
        backgroundNone: {
            pointerEvents: 'none',
        },
        frameBalance: {
            width: theme.units.w(width),
            height: theme.units.h(height),
            //background: 'linear-gradient(90deg, #363232AA 0%, #141212AA 100%)',
            //borderRadius: theme.units.h(2),
            //border: `${Math.max(theme.units.h(0.05), 0.5)}px solid white`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'auto',
            position: 'relative',
            marginBottom: `${theme.units.h(10)}px`,            
        },
        textFrame: {
            fontSize: theme.units.h(2.5),
            color: 'white',
        },
        closeButton: {
            position: 'absolute', 
            width:  theme.units.h(4), 
            height: theme.units.h(4), 
            top: 0,  
            right: 0,
        },
        inputLine: {
            textAlign: 'center',
            margin: theme.units.h(0.5),
        },
        messageText:{           
            height: 55,
            fontFamily: 'Open Sans',
            fontSize: 40,
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            textAlign: 'center',
            color: '#f4f1e6',
        },
        messageTopText:{
            width: 440,
            textTransform: "uppercase",
        },
        messageBottomText:{
            width: 413,
            textAlign: "center",
            marginLeft: '13.5px',
        },
        resetButton:{
            marginTop: 61,
            width: 300,
            height: 80,
            opacity: '0.8',
            borderRadius: 40,
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.5)',
            backgroundColor: '#1ba165',
            '&:hover': {
                backgroundColor: '#1ba165',
            },
        },
        resetButtonText:{
            width: 280,
            height: 30,
            fontFamily: 'Open Sans',
            fontSize: 22,
            fontWeight: 600,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            textAlign: 'center',
            color: '#f4f1e6',
            textTransform: "uppercase",
        }        
    };
});

const BalancePopup: React.FC<Props> = (props) => {
    const classes = useStyles();   
    const popupContent = (
        <>
            <div className={classes.messageTopText + ' ' + classes.messageText}>           
                Game session is over
            </div>
            <div className={classes.messageBottomText + ' ' + classes.messageText}>
                <div>Thank you for playing!</div>
            </div>           
            <div className={classes.inputLine}>
                <Button className={classes.resetButton} onClick={()=>{
                        props.setPopupFlag(PopupKeys.BALANCE, false); 
                        props.resetBalance(props.game.user);
                    }}>
                    <span className={classes.resetButtonText}>restart</span>
                </Button>
            </div>
        </>
    ) 

    return (
        <>
        { props.game.popupsState[PopupKeys.BALANCE] && props.game.user.balance < 100000 ?
            <div className={[classes.ultraFrameBalance, classes.backgroundNone].join(' ')}>
                <div className={classes.frameBalance}>
                    <div className={classes.textFrame}>
                        {popupContent}
                    </div>                
                </div>
            </div>
            :null
        }
        </>
    );
}

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        game: {...state.game},        
    }
}

const mapDispatchToProps = { 
    setPopupFlag:      SyncActions.setPopupFlag,
    setPopupMessage:   SyncActions.setPopupMessage,
    resetBalance:  AsyncActions.updateUserBalance,
};

export default connect<
StateToPropsType,
DispatchToPropsType,
MessageDialogProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(BalancePopup);
