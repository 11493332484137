import React, { useState, useEffect } from 'react';
import { makeStyles, CSSProperties } from '@material-ui/styles';
import BetGridCore from './BetGridCore';
import { Transition } from 'react-transition-group';
import MobileBettingTool from './MobileBettingTool';
import DesktopBettingTool from './DesktopBettingTool';
import StatusBar from '../statusbar/StatusBar';
import ProgressBar from '../statusbar/ProgressBar';
import { ReducersGameType } from '../../redux/reducers/ReducersGame';
import { ReducersGeneralType } from '../../redux/reducers/ReducersGeneral';
import { ReduxStateType } from '../../redux/reducers/Reducers';
import { connect } from 'react-redux';
import { DeviceKeys } from '../../tools/Keys';
import VideoPlayer from '../VideoPlayer';
import UndoButton from './UndoButton';
import RebetDoubleButton from './RebetDoubleButton';
import { PlenikaTheme } from '../CustomThemeProvider';
import CurrencyView from './CurrencyView';
import { getTotalBets, GameStateKeys } from 'plenika-types';
import barExample from '../../assests/bar.png';
import RightPanel from './RightPanel';
import LastNumbers from '../lastnumbers/LastNumbers';
import LuckyNumbers from '../lastnumbers/LuckyNumbers';
import ClassMap from '../../types/ClassMap';
import WinnersList from '../WinnersList';
import BetBorder from './BetBorder';
import NumberItem from '../lastnumbers/NumberItem';
import LastNumberBar from '../lastnumbers/LastNumberBar';
import { ReactComponent as Star } from '../../assests/star.svg';

const defaultTransition: CSSProperties = {
    transition: 'all 2000ms ' /*ease-in-out'*/,
};

const MOBILE_TOP = 15;
const MOBILE_MIDDLE = 55;
const MOBILE_BOTTOM = 100 - MOBILE_TOP - MOBILE_MIDDLE;

const DESKTOP_TOP = 18;
const DESKTOP_MIDDLE = 66.5;
const DESKTOP_BOTTOM = 100 - DESKTOP_TOP - DESKTOP_MIDDLE;

const D_BAR_HEIGHT = 25;

const D_BAR_BACKGROUND = '#0d0100DD';
const D_CENTER_BACKGROUND = '#0d0100BB';

const useStyles = makeStyles((theme: PlenikaTheme) => {
    return {
        fadeUpActive: {
            opacity: 0,
            transform: 'translate(0,-200%)',
            pointerEvents: 'none',
            ...defaultTransition,
        },
        fadeUpUnactive: {
            opacity: 1,
            transform: 'translate(0,0)',
            ...defaultTransition,
        },
        fadeDownActive: {
            opacity: 0,
            transform: 'translate(0,200%)',
            pointerEvents: 'none',
            ...defaultTransition,
        },
        fadeDownUnactive: {
            opacity: 1,
            transform: 'translate(0,0)',
            ...defaultTransition,
        },

        dBettingToolUnactive: {
            ...defaultTransition,
            opacity: 1,
        },
        dBettingToolActive: {
            ...defaultTransition,
            transform: 'translateY(100%)',
            opacity: 0,
            maxHeight: '0px!important',
        },

        dRotateUnactive: {
            ...defaultTransition,
        },
        dRotateActive: {
            ...defaultTransition,
            transform: 'rotateX(45deg)',
            top: `-${theme.units.h(2)}px`,
        },

        dTitleBarUnactive: {
            ...defaultTransition,
        },
        dTitleBarActive: {
            ...defaultTransition,
            top: `${theme.units.h(9)}px!important`,
        },

        dTranagleUnactive: {
            ...defaultTransition,

        },
        dTranagleActive: {
            ...defaultTransition,
            clipPath: 'polygon(0% 100%, 50% 100%, 100% 100%)!important',
        },

        dUltraFrameUnactive: {
            ...defaultTransition,
        },
        dUltraFrameActive: {
            ...defaultTransition,
            maxHeight: `${theme.units.h(D_BAR_HEIGHT - 2)}px!important`,
        },

        dShadingUnactive: {
            ...defaultTransition,
        },
        dShadingActive: {
            ...defaultTransition,
            backgroundColor: `${D_BAR_BACKGROUND}!important`,
        },

        opacityVisable: {
            opacity: `1!important`,
        },
        opacityUnvisable: {
            opacity: '0!important',
        },

        rotateMobileActive: {
            transform: 'rotateX(30deg) translateY(33%) scale(0.5)',
            ...defaultTransition,
        },
        rotateMobileUnactive: {
            transform: 'rotateX(0deg)',
            ...defaultTransition,
        },
        rotateDesktopActive: {
            transform: 'rotateX(35deg) translateY(20%) scale(0.9)',
            ...defaultTransition,
        },
        rotateDesktopUnactive: {
            transform: 'rotateX(0deg)',
            ...defaultTransition,
        },

        dTopFrame: {
            // height:     `${DESKTOP_TOP}%`, 
            // maxHeight:  `${DESKTOP_TOP}%`,
        },
        dMiddleFrame: {
            // height:     `${DESKTOP_MIDDLE}%`, 
            // maxHeight:  `${DESKTOP_MIDDLE}%`,
        },
        dBottomFrame: {
            // height:     `${DESKTOP_BOTTOM}%`, 
            // maxHeight:  `${DESKTOP_BOTTOM}%`,
        },
        dUltraFrame: {
            padding: theme.units.h(0),
            position: 'relative',
        },
        dTranagle: {
            position: 'relative',
            top: theme.units.h(0.1),
            backgroundColor: D_CENTER_BACKGROUND,
            height: theme.units.h(2),
            clipPath: 'polygon(0% 100%, 50% 0%, 100% 100%)',
        },
        dFrame: {
            backgroundColor: D_CENTER_BACKGROUND,
            padding: theme.units.h(1),
            borderRadius: theme.units.h(3),
            maxHeight: theme.units.h(40),
        },
        dSubFrame: {
            // border: `solid ${Math.max(theme.units.h(0.1), 0.4)}px`, 
            // borderWidth: '2px',
            // borderStyle: 'solid',
            // borderImage: 'linear-gradient(115deg, #4e493a 0%, #36373c 25%, #2c2d2f 75%, #391515 99%)',
            margin: theme.units.h(1),
            padding: theme.units.h(1),
            paddingBottom: theme.units.h(0),
            paddingTop: theme.units.h(6),
            borderRadius: theme.units.h(2),
            position: 'relative',



            /*'&:before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'red',
            },*/
        },
        dShading: {
            position: 'absolute',
            height: '38%',
            width: '100%',
            bottom: '-0.5%',
            left: 0,
        },
        gridFrame: {
            margin: 'auto',
            position: 'relative',
        },
        dContainer: {
            display: 'flex',
            alignItems: 'flex-end',
            position: 'absolute',
            bottom: 0,
            width: '100%',
        },
        dSideRadius: {
            width: theme.units.h(8),
            height: theme.units.h(8),
            borderRadius: '50%',
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0 100%)',
            position: 'absolute',
        },
        dSideRadiusLeftBtttom: {
            left: theme.units.h(0) - 1,
            bottom: theme.units.h(0) - 1,
            boxShadow: `-${theme.units.h(4)}px ${theme.units.h(4)}px 0 0 ${D_BAR_BACKGROUND}`,
        },
        dSideRadiusRightBottom: {
            right: theme.units.h(0) - 1,
            bottom: theme.units.h(0) - 1,
            boxShadow: `${theme.units.h(4)}px ${theme.units.h(4)}px 0 0 ${D_BAR_BACKGROUND}`,
        },
        dSideRadiusRightTop: {
            right: theme.units.h(0) - 1,
            top: theme.units.h(2) - 1,
            boxShadow: `${theme.units.h(4)}px -${theme.units.h(4)}px 0 0 ${D_BAR_BACKGROUND}`,
        },
        dSideRadiusLeftTop: {
            left: theme.units.h(0) - 1,
            top: theme.units.h(2) - 1,
            boxShadow: `-${theme.units.h(4)}px -${theme.units.h(4)}px 0 0 ${D_BAR_BACKGROUND}`,
        },
        dBarFrame: {
            textAlign: 'center',
            zIndex: 1000,
            position: 'relative',
            left: 0,
            top: theme.units.h(6.5),
        },
        dStatusBarFrame: {
            height: theme.units.h(2.5),
            lineHeight: `${theme.units.h(2.5)}px`
        },
        dProggressBarFrame: {
            height: theme.units.h(0.7),
            paddingBottom: theme.units.h(0.5),
        },
        dBettingToolFrame: {
            paddingTop: theme.units.h(0.2),
            height: theme.units.h(7), // none
            maxHeight: theme.units.h(7),//7
            textAlign: 'center',
        },
        betBorderFrame: {
            top: -theme.units.h(1),
            left: 0,
            position: 'absolute',
            width: '100%',
            pointerEvents: 'none',
        },
        sideGridBar: {
            height: theme.units.h(D_BAR_HEIGHT),
            backgroundColor: D_BAR_BACKGROUND,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
        },

        mTopFrame: {
            height: `${MOBILE_TOP}%`,
            maxHeight: `${MOBILE_TOP}%`,
            backgroundColor: '#0d0100DD',
            textAlign: 'center',
        },
        mMiddleFrame: {
            height: `${MOBILE_MIDDLE}%`,
            backgroundColor: '#0d0100DD',
            paddingTop: '1%',
        },
        mBottomFrame: {
            height: `${MOBILE_BOTTOM}%`,
            backgroundColor: '#0d0100DD',
        },
        mSubFrame: {
            height: '100%',
        },
        mFrame: {
            height: '100%',
            position: 'absolute',
            width: '100%',
            top: '0',
            left: '0',
        },
        toolsFrame: {

        },
        toolsFrameItem: {
            height: '100%',
            margin: '0.2%',
            display: 'inline-block',
            verticalAlign: 'middle'
        },
        mobileBettingToolFrame: {
            width: theme.units.h(14),
        },

        title: {
            textAlign: 'center',
        },
        fontStyle: {
            fontFamily: "'Alatsi', sans-serif",
        },
        star: {
            zIndex: 2000,
            position: 'absolute',
            width: theme.units.h(3),
            height: theme.units.h(3),
            top: `calc(${theme.units.h(5.8)}px - ${theme.units.h(1.5)}px)`,
            right: `calc(50% + ${theme.units.h(1.5)}px)`,
        },
    }
});

export interface BetGridProps {
};


interface StateToPropsType {
    general: ReducersGeneralType;
    game: ReducersGameType,
}

interface DispatchToPropsType {
}

type Props = BetGridProps & StateToPropsType & DispatchToPropsType;

const BetGrid: React.FC<Props> = (props) => {
    const classes = useStyles();

    const fadeDownMap: ClassMap = {
        entering: classes.fadeDownActive,
        entered: classes.fadeDownActive,
        exiting: classes.fadeDownUnactive,
        exited: classes.fadeDownUnactive,
    };
    const fadeUpMap: ClassMap = {
        entering: classes.fadeUpActive,
        entered: classes.fadeUpActive,
        exiting: classes.fadeUpUnactive,
        exited: classes.fadeUpUnactive,
    };
    const rotateMobileMap: ClassMap = {
        entering: classes.rotateMobileActive,
        entered: classes.rotateMobileActive,
        exiting: classes.rotateMobileUnactive,
        exited: classes.rotateMobileUnactive,
    };
    const dRotateMap: ClassMap = {
        entering: classes.dRotateActive,
        entered: classes.dRotateActive,
        exiting: classes.dRotateUnactive,
        exited: classes.dRotateUnactive,
    };
    const dBettingToolMap: ClassMap = {
        entering: classes.dBettingToolActive,
        entered: classes.dBettingToolActive,
        exiting: classes.dBettingToolUnactive,
        exited: classes.dBettingToolUnactive,
    };
    const dTitleBarMap: ClassMap = {
        entering: classes.dTitleBarActive,
        entered: classes.dTitleBarActive,
        exiting: classes.dTitleBarUnactive,
        exited: classes.dTitleBarUnactive,
    };
    const dShadingFrameMap: ClassMap = {
        entering: classes.dUltraFrameActive,
        entered: classes.dUltraFrameActive,
        exiting: classes.dUltraFrameUnactive,
        exited: classes.dUltraFrameUnactive,
    };
    const dTranagleMap: ClassMap = {
        entering: classes.dTranagleActive,
        entered: classes.dTranagleActive,
        exiting: classes.dTranagleUnactive,
        exited: classes.dTranagleUnactive,
    };
    const opacityMap: ClassMap = {
        entering: classes.opacityVisable,
        entered: classes.opacityVisable,
        exiting: classes.opacityUnvisable,
        exited: classes.opacityUnvisable,
    };
    const dShadingMap: ClassMap = {
        entering: classes.dShadingActive,
        entered: classes.dShadingActive,
        exiting: classes.dShadingUnactive,
        exited: classes.dShadingUnactive,
    };

    const timeout = 500;
    const perspective = props.general.dimensions ? props.general.dimensions.height : 0;
    const isOpen = [
        GameStateKeys.RESULTS, 
        GameStateKeys.PLACE_YOUR_BETS, 
        GameStateKeys.LAST_BETS, 
        GameStateKeys.BETS_CLOSING,
    ].includes(props.game.round.current_state.name);
    let totalBet = getTotalBets(props.game.betActions);
    let totalBalance = props.game.user.balance;

    useEffect(() => {
        const elem1 = document.getElementById('div1');
        const elem2 = document.getElementById('div2');
        if (elem1) {
            elem1.scrollLeft = perspective * 2;
        }
        if (elem2) {
            elem2.scrollLeft = perspective * 2;
        }
    })

    if (props.general.device === DeviceKeys.MOBILE) {
        return (
            <div className={[classes.mFrame, classes.fontStyle].join(' ')} style={{ perspective: `${perspective}px` }}>
                <Transition in={!isOpen} timeout={timeout}>
                    {tState => (
                        <div className={[classes.mSubFrame, rotateMobileMap[tState]].join(' ')}>
                            <div className={[classes.mTopFrame, fadeUpMap[tState]].join(' ')}>
                            </div>
                            <div className={classes.mMiddleFrame}>
                                <BetGridCore />
                            </div>
                            <div className={[classes.mBottomFrame, fadeDownMap[tState]].join(' ')}>
                                <div style={{ display: 'flex', height: '100%', width: '100%' }}>
                                    <div style={{ flex: '3', height: '100%', display: 'inline-block' }}>
                                        <WinnersList />
                                    </div>
                                    <div style={{ flex: '2', height: '100%', display: 'inline-block' }}>
                                        {
                                            props.game.nanoStreamToken !== '' &&
                                            <VideoPlayer id={"gridPlayer"} stream_id={props.game.nanoStreamToken} />
                                        }
                                    </div>
                                    <div style={{ flex: '3', flexWrap: 'nowrap', width: '100%', display: 'inline-block', textAlign: 'center' }}>
                                        <div className={classes.toolsFrameItem}>
                                            <UndoButton />
                                        </div>
                                        <div className={[classes.toolsFrameItem, classes.mobileBettingToolFrame].join(' ')}>
                                            <MobileBettingTool />
                                        </div>
                                        <div className={classes.toolsFrameItem}>
                                            <RebetDoubleButton />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Transition>

                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}>
                    <LastNumberBar />
                    <Star className={classes.star} />
                    <div style={{ textAlign: 'center' }}><StatusBar /></div>
                </div>

                <div style={{ position: 'absolute', bottom: 0, left: 0 }}>
                    <CurrencyView amount={totalBalance} title={'BALANCE'} />
                </div>
                <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                    <CurrencyView amount={totalBet} title={'TOTAL BET'} selected right />
                </div>
            </div>
        );
    } else {
        return (
            <>
                <div className={[classes.dContainer, classes.fontStyle].join(' ')} style={{ perspective: `${perspective}px` }}>
                    <div className={classes.sideGridBar} style={{ flex: 3 }}>
                        <div style={{ flex: 1 }}>
                            <LastNumbers />
                            <LuckyNumbers />
                        </div>
                        <div>
                            <CurrencyView amount={totalBalance} title={'BALANCE'} />
                            <CurrencyView amount={totalBet} title={'TOTAL BET'} selected />
                        </div>
                    </div>
                    <Transition in={!isOpen} timeout={timeout}>
                        {tState => (
                            <div style={{ flex: 5 }}>
                                <div className={[classes.dBarFrame, dTitleBarMap[tState]].join(' ')}>
                                    <div className={classes.dProggressBarFrame}><ProgressBar /></div>
                                    <div className={classes.dStatusBarFrame}><StatusBar /></div>
                                </div>
                                <div className={[classes.dUltraFrame].join(' ')}>

                                    <div className={[classes.dSideRadius, classes.dSideRadiusLeftBtttom].join(' ')} />
                                    <div className={[classes.dSideRadius, classes.dSideRadiusRightBottom].join(' ')} />
                                    <div className={[classes.dSideRadius, classes.dSideRadiusLeftTop, opacityMap[tState]].join(' ')} />
                                    <div className={[classes.dSideRadius, classes.dSideRadiusRightTop, opacityMap[tState]].join(' ')} />

                                    <div className={[classes.dTranagle, dTranagleMap[tState], dShadingMap[tState]].join(' ')}>
                                    </div>
                                    <div className={[classes.dFrame, dShadingFrameMap[tState], dShadingMap[tState]].join(' ')}>
                                        <div className={[classes.dSubFrame, dRotateMap[tState]].join(' ')} style={{/*...{paddingTop: perspective*0.07, transform: 'rotateX(45deg)', top: perspective*0.01}*/ }}>
                                            <div className={classes.betBorderFrame}>
                                                <BetBorder />
                                            </div>
                                            <div>
                                                <BetGridCore />
                                            </div>
                                            <div className={[classes.dBettingToolFrame, dBettingToolMap[tState]].join(' ')}>
                                                <div style={{ display: 'inline-block', verticalAlign: 'top' }}><UndoButton /></div>
                                                <div style={{ display: 'inline-block', verticalAlign: 'bottom', height: '100%' }}><DesktopBettingTool /></div>
                                                <div style={{ display: 'inline-block', verticalAlign: 'top' }}><RebetDoubleButton /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Transition>
                    <RightPanel />
                </div>
            </>
        );
    }
}

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        general: { ...state.general },
        game: { ...state.game },
    }
}

const mapDispatchToProps = {};

export default connect<
    StateToPropsType,
    DispatchToPropsType,
    BetGridProps,
    ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(BetGrid);
