import socketIOClient from "socket.io-client";
import Tools from "../tools/Tools";
import { IFromRegistration, IRoundStateChange, IUser, IRegistretionRequest, GameStateKeys, IRoundSummary, IRoundSummaryUser, Bet } from 'plenika-types';
import { PopupKeys } from "../tools/Keys";
import LocalStorageService from "./LocalStorageService";
import { SocketProps } from "../components/SocketServiceComp";

interface ISocketService {
    socket?: SocketIOClient.Socket;
}

export class SocketService implements ISocketService {
    public socket?: SocketIOClient.Socket;
    // @ts-ignore
    private props: SocketProps;

    constructor() { }

    startSesstion(/*props:SocketServiceProps*/) {
        return new Promise((resolve, reject) => {
            this.socket = socketIOClient(Tools.getBaseUrl());

            // Listenres - Start

            this.socket.on('login', (data: any) => {
                console.log('data from login : ', data);
                if (data.success) {
                    LocalStorageService.saveToken(data.token);
                    this.props.setToken((data.token as string));

                    this.props.setNanoStreamToken(data.nano_stream_id);
                    this.props.setLoginTime();
                    // TODO make it right
                    this.props.setUser(data.user);
                    this.props.setPopupLoginFlag(false);
                    this.props.setPopupFlag(PopupKeys.MESSAGE, false);
                    this.emitRegisterRound();
                }
            });

            this.socket.on('registerToRoundSuccess', (data: IFromRegistration) => {
                // create reducer for socket connection success (auth)
                const timeStartedUnix: string = data.round.time_started.toString();
                data.round.time_started_unix = new Date(timeStartedUnix).getTime();
                // console.log('set round called');
                this.props.setRound(data.round);
                if (data.oldResults) {
                    this.props.setOldResults(data.oldResults);
                }
            });

            this.socket.on('registerToRoundFailure', () => {
                console.log('in registerToRoundFailure');
            });

            this.socket.on('notifyStatus', (status: IRoundStateChange) => {
                // console.log('notify : ', status);
                let roundToUpdate = this.props.game.round;
                if (status.state.name === 'place_your_bets') {
                    this.props.clearBets();
                    console.log('please udapte socket to have fresh props');
                    // TODO AFTER UPDATE SOCKET !!! 
                    // if(props.general.token) {
                    this.emitRegisterRound();
                    // }
                }
                if (status.state.name === GameStateKeys.BETS_CLOSED) {
                    if (this.props.game.user._id) {
                        console.log('post bets');
                        this.props.postBets();
                    }
                }
                if (status.state.name === GameStateKeys.GAME_ROUND) {
                    // Update lucky number
                    roundToUpdate.result = status.result;
                    if (status.oldResults) {
                        this.props.setOldResults(status.oldResults);
                    }
                }
                if (status.state.name === GameStateKeys.RESULTS) {
                    // this.props.setResult(status.result);
                    // Udpate balance
                    const spinResult: number | null = status.result && status.result.spinResult || null;
                    const currentBalances = status.usersBalanceNew;
                    const oldBalances = status.usersBalanceOld;

                    if (currentBalances && oldBalances) {
                        let proccedPopup = true;
                        const currentUserBlalance = currentBalances[this.props.game.user._id];
                        const oldUserBalance = oldBalances[this.props.game.user._id];
                        const balanceChange = currentUserBlalance - oldUserBalance;
                        this.props.setUser({
                            ...this.props.game.user,
                            balance: currentUserBlalance,
                        });
                        let popupMessage = '';
                        if (spinResult) {
                            const spinColor = new Bet({ name: spinResult.toString(), amount: 0 }).color;

                            popupMessage += "Result : " + spinResult.toString() + ' ' + spinColor;
                        }
                        const formatedPrettyFunds = Tools.printFunds(Math.abs(balanceChange));
                        if (balanceChange > 0) {
                            // User win funds
                            popupMessage += `\nYou win ${formatedPrettyFunds}`;
                        } else if (balanceChange < 0) {
                            // User lose funds
                            popupMessage += `\nYou lost ${formatedPrettyFunds}`;
                        }
                        else {
                            popupMessage += `\nYour balance was not changed`;
                            // No need to open popup, lets return
                            // proccedPopup = false;

                        }
                        if (proccedPopup) {
                            // Continue to popup
                            this.props.setPopupMessage(popupMessage);
                            this.props.setPopupFlag(PopupKeys.MESSAGE, true);
                            const closePopup = () => {
                                this.props.setPopupMessage('');
                                this.props.setPopupFlag(PopupKeys.MESSAGE, false);
                            }
                            setTimeout(closePopup, 2000)
                        }

                        // if (balanceChange <= 1000) {
                        //     this.props.setPopupMessage("");
                        //     this.props.setPopupFlag(PopupKeys.BALANCE, true);
                        // }
                    }
                }
                roundToUpdate.current_state = status.state;
                if (status.result) {
                    roundToUpdate.result = status.result;
                }
                this.props.setRound({ ...roundToUpdate });
            });
            // Listenres - End

            // Finish 
            this.socket.on('connect', (data: any) => {
                console.log('connect', data);
                resolve(data);
            });

            this.socket.on('disconnect', (data: any) => {
                console.log('disconnect', data);
                resolve(data);
            });

        });
    }

    setProps(props: SocketProps) {
        this.props = props;
    }

    emitLogin(user: IUser) {
        console.log('login : value : ', user);
        if (!this.socket) {
            throw 'startSession() require before any other funciton';
        }
        this.socket.emit('login', {
            user: user,
        });
    }

    emitLoginByToken(userToken: string) {
        console.log(`emitLoginByToken user token: ${userToken}`);
        if (!this.socket) {
            throw 'startSession() require before any other funciton';
        }
        this.socket.emit('loginByToken', { token: userToken });
    }

    emitRegisterRound() {
        console.log('emit regis')
        if (!this.socket) {
            throw 'startSession() require before any other funciton';
        }
        this.socket.emit('registerToRound', {
            token: LocalStorageService.loadToken(),
        } as IRegistretionRequest);
    }
}

export default new SocketService();