"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var betData = {
  straight: {
    name: "straight",
    min: 20,
    max: 5000,
    payout: 29,
    display_name: "Straight Up"
  },
  split: {
    name: "split",
    min: 20,
    max: 10000,
    payout: 17,
    display_name: "Split"
  },
  street: {
    name: "street",
    min: 20,
    max: 15000,
    payout: 11,
    display_name: "Street"
  },
  corner: {
    name: "corner",
    min: 20,
    max: 20000,
    payout: 8,
    display_name: "Corner"
  },
  line: {
    name: "line",
    min: 20,
    max: 30000,
    payout: 5,
    display_name: "Line"
  },
  column: {
    name: "column",
    min: 20,
    max: 60000,
    payout: 2,
    display_name: "Column"
  },
  dozen: {
    name: "dozen",
    min: 20,
    max: 60000,
    payout: 2,
    display_name: "Dozen"
  },
  red_black: {
    name: "red_black",
    min: 20,
    max: 100000,
    payout: 1,
    display_name: "Red/Black"
  },
  even_odd: {
    name: "even_odd",
    min: 20,
    max: 100000,
    payout: 1,
    display_name: "Even/Odd"
  },
  one_eighteen: {
    name: "one_eighteen",
    min: 20,
    max: 100000,
    payout: 1,
    display_name: "1-18/19-35"
  }
};
exports.default = betData;