import { ActionKeys, BetTags, ActionStatusKeys, PopupKeys } from '../../tools/Keys';
import {
    IUser,
    IRound,
    Round,
    AggregateRoundBets,
    ILocation,
    ILoginResponse,
    getTotalBets,
    IRoundResult
}
    from 'plenika-types';
import Tools from '../../tools/Tools';

export interface PopupsState {
    [key: string]: boolean;
}

export interface FlatLuckyNumber {
    luckyNumber: number;
    multi: number;
}

export interface ReducersGameType {
    betActions: BetAction[];
    lastBet: BetAction[];
    bets: ILocation[]; // TODO remove ? 
    selectedChip: number;
    round: IRound;
    user: IUser;
    // for lastNumber use
    oldResults: IRoundResult[];
    flatLuckyNumbers: FlatLuckyNumber[];

    bar_color: string;

    timestamp: number;
    stamp_minus_started?: number;
    time_left: number;
    percentage_left: number;
    total_percentage_left: number;
    total_time_left: number;

    show_progress_bar: boolean;
    show_timer_bar: boolean;

    popupMessage: string;
    popupsState: PopupsState;
    popupLogin: boolean;

    loginTime?: number;
    nanoStreamToken: string;
}

export interface BetAction {
    order: number;
    active: boolean;
    bets: RoundBet[];
    tag?: string;
}

export interface RoundBet {
    betId: string;
    betAmount: number;
}

function getInitialState(): ReducersGameType {
    return {
        betActions: [],
        bets: [],
        user: { username: '', balance: 100000, _id: "" },
        lastBet: [],
        round: new Round({}), // TODO class in state redux
        // this is the time left to bet for this round. only for place_your_bets
        selectedChip: 100,
        timestamp: new Date().getTime(),
        time_left: -1,
        total_time_left: -1,
        bar_color: '',
        percentage_left: 0,
        show_progress_bar: false,
        show_timer_bar: false,
        total_percentage_left: 0,
        oldResults: [],
        flatLuckyNumbers: [],
        /*luckyNumberBundle: {
            index: -1, 
            luckyNumbers: [], 
            spinResult: -1,
        },*/
        // TODO maybe add a new reducer for popups ?  
        popupMessage: '',
        popupLogin: false,
        popupsState: Object.keys(PopupKeys)
            .map(key => ({ [key]: false }))
            .reduce((popState, sum) => ({ ...sum, ...popState })),
        nanoStreamToken: ''


    };
}

export default function (state = getInitialState(), action: any): ReducersGameType {
    let balance;
    let actions;

    switch (action.type) {
        case ActionKeys.INTIATE_USER:
            if (action.status === ActionStatusKeys.SUCCESS) {
                const response = action.response as ILoginResponse;
                return {
                    ...state,
                    user: response.user,
                }
            }
            break;
        case ActionKeys.SET_USER:
            return {
                ...state,
                user: action.value,
            };
        case ActionKeys.ADD_BETS:
            // Start - Initinal Calculating
            const bets = action.bets as RoundBet[];
            const tag = action.tag as string;
            const reports = Tools.addBetsValidReports(
                state.betActions,
                bets,
                state.user.balance,
            );
            // End - Initinal Calculating

            // Start - Setting Vars
            let actions = state.betActions;
            let messagePopupOpen = state.popupsState[PopupKeys.MESSAGE];
            let popupMessage = state.popupMessage;
            let userBalance = state.user.balance;
            // set popup message
            if (reports.find(r => !r.valid)) {
                const messages = reports.filter(r => r.message).map(r => r.message);
                popupMessage = messages.length > 0 ? messages[0] : '';
                messagePopupOpen = true;
            }
            // check and add new bet action
            const betsToAdd = reports.filter(r => r.add).map(r => r.add) as RoundBet[];
            if (betsToAdd.length > 0) {
                const newAction = {
                    active: true,
                    order: actions.length,
                    bets: betsToAdd,
                    tag: tag,
                };
                actions = [...actions, newAction];
                userBalance -= getTotalBets([newAction]);
            }
            // End - Setting Vars
            return {
                ...state,
                betActions: actions,
                popupsState: { ...state.popupsState, [PopupKeys.MESSAGE]: messagePopupOpen },
                popupMessage: popupMessage,
                user: { ...state.user, balance: userBalance },
            }

        case ActionKeys.ADD_BET_ACTION:
            const actions2: BetAction[] = [...state.betActions, {
                ...action.value,
                order: state.betActions.length
            }];
            const newUserBalance: number = state.user.balance - getTotalBets([action.value]);
            return {
                ...state,
                betActions: actions2,
                user: {
                    ...state.user,
                    balance: newUserBalance
                }
            };

        case ActionKeys.CLEAR_BETS:
            return { ...state, lastBet: state.betActions, betActions: [], bets: [] }

        case ActionKeys.RE_BET:
        /*case ActionKeys.DOUBLE_BET:
            const additionalBets = action.type === ActionKeys.DOUBLE_BET 
                ? state.betActions 
                : state.lastBet;
            const tag = action.type === ActionKeys.DOUBLE_BET
                ? BetTags.DOUBLE_BET 
                : BetTags.RE_BET;
            const actions: BetAction[] = [...state.betActions, {
                active: true,
                order: state.betActions.length,
                bets: Tools.reduceBetActions(additionalBets),
                tag: tag,
            }];
            const balance = state.user.balance - getTotalBets(actions);
            return {
                ...state, 
                betActions: actions, 
                user: {...state.user, balance: balance}
            };*/

        case ActionKeys.SET_SELECTED_CHIP:
            return {
                ...state,
                selectedChip: action.selected,
            };

        case ActionKeys.UPDATE_ROUND:
            return { ...state, round: action.round };


        case ActionKeys.UNDO_BET:
            const totalBefore: number = getTotalBets(state.betActions);
            const newActions = [...state.betActions];
            const activeActions = newActions.filter(r => r.active);
            if (activeActions.length < 1) {
                return state;
            }
            const lastActiveBet = activeActions.reduce((r1, r2) => r1.order > r2.order ? r1 : r2, newActions[0]);
            lastActiveBet.active = false;
            const newBets: ILocation[] = new AggregateRoundBets(newActions, 1000000).locations;
            const totalBetUndo: number = getTotalBets(newActions);
            const undoBalance: number = state.user.balance + (totalBefore - totalBetUndo);
            return { ...state, bets: newBets, betActions: newActions, user: { ...state.user, balance: undoBalance } };


        case ActionKeys.SET_ROUND_STATUS:
            return {
                ...state,
                round: {
                    ...state.round,
                    current_state: action.status,
                }
            };
        case ActionKeys.POST_BETS:
            // Needed ?     
            return {
                ...state,
                round: {
                    ...state.round,
                    // lucky_numbers:action.response.lucky_numbers
                }

            };
        case ActionKeys.SET_RESULT:
            return {
                ...state,
                round: {
                    ...state.round,
                    result: action.result
                }
            };
        case ActionKeys.SET_POPUP_FLAG:
            return {
                ...state,
                popupsState: {
                    ...state.popupsState,
                    [action.key]: action.value,
                }
            };
        case ActionKeys.SET_POPUP_MESSAGE:
            return {
                ...state,
                popupMessage: action.value,
            };
        case ActionKeys.SET_POPUP_LOGIN_FLAG:
            return {
                ...state,
                popupLogin: action.value,
            };
        case ActionKeys.SET_LUCKY_NUMBERS:
            // Needed ? 
            return {
                ...state,
                // luckyNumberBundle: action.value,
            };
        case ActionKeys.SET_LOGIN_TIME:
            return {
                ...state,
                loginTime: new Date().getTime(),
            };
        case ActionKeys.SET_OLD_RESULTS:
            const oldResults = action.value as IRoundResult[];
            const oldLuckysResults = [] as FlatLuckyNumber[];
            oldResults.forEach(res => {
                if (res.luckyNumbers && res.multiplication) {
                    for (let i = 0; i < res.luckyNumbers.length; i++) {
                        oldLuckysResults.push({
                            luckyNumber: res.luckyNumbers[i],
                            multi: res.multiplication[i],
                        });
                    }
                }
            });
            return {
                ...state,
                oldResults: action.value,
                flatLuckyNumbers: oldLuckysResults,
            };

        case ActionKeys.SET_NANO_STREAM_TOKEN:
            return { ...state, nanoStreamToken: action.token }
    }

    return state;
}
