import React from 'react';
import { connect } from 'react-redux';
import { ReduxStateType } from '../redux/reducers/Reducers';
import { makeStyles } from '@material-ui/core/styles';
import RatioContainer, { OnSizeChange } from '../components/RatioContainer';
import { ReducersGeneralType } from '../redux/reducers/ReducersGeneral';
import RightColumn from '../components/gameui/RightColumn';
import LeftColumn from '../components/gameui/LeftColumn';
import CenterColumn from '../components/gameui/CenterColumn';
import SyncActions from '../redux/actions/SyncActions';
import { DeviceKeys } from '../tools/Keys';
import SideMenu from '../components/SideMenu';
import RotateScreen from '../components/RotateScreen';
import BetGrid from '../components/betgrid/BetGrid';
import VideoPlayer from '../components/VideoPlayer';
import MessageDialog from '../components/popup/MessageDialog';
import BalancePopup from '../components/popup/BalancePopup';
import SwipeTouchScreen from '../components/SwipeTouchScreen';
import DesktopTablePopup from '../components/popup/DesktopTablePopup';
import { PlenikaTheme } from '../components/CustomThemeProvider';
import { ReducersGameType } from '../redux/reducers/ReducersGame';

export interface GamePageProps {

}

interface StateToPropsType {
    general: ReducersGeneralType;
    game: ReducersGameType;
}

interface DispatchToPropsType {
    updateDimensions: typeof SyncActions.updateDimensions;
}

type Props = GamePageProps & StateToPropsType & DispatchToPropsType;

const useStyles = makeStyles((theme: PlenikaTheme) => ({
    supremeRoot: {
        height: '100vh',
        overflow: 'hidden',
    },
    flexContainer: {
        width: '100%',
        height: theme.general.isMobile ? '95%' : '100%',
        paddingHeight: theme.general.isMobile ? '5%' : '0',
        position: 'absolute',
        display: 'flex',
    },
    fullScale: {
        width: '100%',
        height: '100%',
    },
    topLeft: {
        top: 0,
        left: 0,
    },
    grandient: {
        background: 'linear-gradient(115deg, rgba(136,111,12,1) 0%, rgba(52,69,73,1) 25%, rgba(179,8,52,0.9192051820728291) 300%)',
        display: 'flex',
    },
    flexCenter: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    landscapeIcon: {
        maxWidth: '50%',
        maxHeight: '50%',
        filter: 'brightness(0) invert(1)',
    },
    contentContainer: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    darkBacground: {
        backgroundColor: '#0d0000', // TODO use theme
    },
    whiteScreen: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'white',
    },
}));

const GamePage: React.FC<Props> = (props) => {
    const classes = useStyles();
    const isMobile = props.general.device === DeviceKeys.MOBILE;

    // Vars
    let showRoateScreen = false;
    // let gridShadeHeight: number | undefined = undefined;
    // React Nodes
    let content;
    let sideBar = undefined;

    // Make vars ready
    if (props.general.screenDimensions && props.general.dimensions) {
        showRoateScreen = isMobile && props.general.screenDimensions.width < props.general.screenDimensions.height;
        // gridShadeHeight = props.general.dimensions.width / 4.75247524752;
    }


    // Make nodes ready
    if (showRoateScreen) {
        content = (
            <RotateScreen />
        );
    } else if (isMobile && !props.general.fullScreen) {
        content = (
            <SwipeTouchScreen />
        );
    } else {
        content = (
            <>
                {/* Background Layer */}
                <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}>
                    {
                        props.game.nanoStreamToken !== '' &&
                        <VideoPlayer id={"mainPlayer"} stream_id={props.game.nanoStreamToken} />
                    }
                </div>
                {/*<div style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'white'}}/>*/}

                {/* UI Layer */}
                <div className={[classes.flexContainer, classes.topLeft].join(' ')}>
                    <LeftColumn isMobile={isMobile} open={false} />
                    <CenterColumn isMobile={isMobile} open={false} />
                    <RightColumn isMobile={isMobile} open={false} />
                </div>

                {/* Grid Layer */}
                <BetGrid />

                {/*Popups*/}
                <MessageDialog />
                <DesktopTablePopup />
                <BalancePopup />
            </>
        );
    }
    if (isMobile) {
        sideBar = (
            <SideMenu />
        );
    }

    // Make handlers ready
    const onSizeChange: OnSizeChange = (w, h, sw, sh, sbw) => {
        props.updateDimensions(
            { width: w, height: h },
            { width: sw, height: sh },
            sbw,
        );
    };

    // Done render
    return (
        <div className={classes.supremeRoot}>
            <RatioContainer
                onSizeChange={onSizeChange}
                ratio={[16, 9]}
                maxHeight={900}
                maxWidth={1600}
                sideBar={sideBar}
            >
                <div className={classes.contentContainer}>
                    {content}
                </div>
            </RatioContainer>
        </div>
    )
}

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        general: { ...state.general },
        game: { ...state.game },
    }
}

const mapDispatchToProps = { updateDimensions: SyncActions.updateDimensions };

export default connect<
    StateToPropsType,
    DispatchToPropsType,
    GamePageProps,
    ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(GamePage);
