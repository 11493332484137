import { ActionKeys, PopupKeys } from '../../tools/Keys';
import { Dimensions } from '../reducers/ReducersGeneral';
import { IRound, ILocation, RoundBet, ILuckyNumber, IUser, IRoundResult } from 'plenika-types';
import { BetAction } from '../reducers/ReducersGame';

const updateDevice = (deviceKey: string) => {
    return {
        type: ActionKeys.UPDATE_DEVICE,
        value: deviceKey,
    }
}

const updateDimensions = (dimensions?: Dimensions, screenDimensions?: Dimensions, sideBarWidth?: number) => {
    return {
        type: ActionKeys.UPDATE_DIMENSIONS,
        dimensions: dimensions,
        screenDimensions: screenDimensions,
        sideBarWidth,
    };
}

const addBets = (bets: RoundBet[], tag?: string) => {
    return {
        type: ActionKeys.ADD_BETS,
        bets: bets,
        tag: tag,
    };
}
const clearBets = () => {
    return {
        type: ActionKeys.CLEAR_BETS
    };
}

const undoBet = () => {
    return {
        type: ActionKeys.UNDO_BET,
    };
}

const reBet = () => {
    return {
        type: ActionKeys.RE_BET,
    };
}

const doubleBet = () => {
    return {
        type: ActionKeys.DOUBLE_BET,
    };
}

const setSetup = (setup: boolean) => {
    return {
        type: ActionKeys.SET_SETUP,
        value: setup,
    }
}

const setSelectedChip = (selectedAmount: number) => {
    return {
        type: ActionKeys.SET_SELECTED_CHIP,
        selected: selectedAmount,
    }
}

const setRound = (round: IRound) => {
    return {
        type: ActionKeys.UPDATE_ROUND,
        round: round
    }
}

const setRoundStatus = (status: string) => {
    return {
        type: ActionKeys.SET_ROUND_STATUS,
        status
    }
}

const setResult = (result: number) => {
    return {
        type: ActionKeys.SET_RESULT,
        result
    }
}


const setPopupFlag = (popupKey: string, open: boolean) => {
    return {
        type: ActionKeys.SET_POPUP_FLAG,
        key: popupKey,
        value: open,
    }
}

const setPopupMessage = (message: string) => {
    return {
        type: ActionKeys.SET_POPUP_MESSAGE,
        value: message,
    }
}

const addBetAction = (betAction: BetAction) => {
    return {
        type: ActionKeys.ADD_BET_ACTION,
        value: betAction,
    }
}

const setFullScreen = (fullScreen: boolean) => {
    return {
        type: ActionKeys.SET_FULL_SCREEN,
        value: fullScreen,
    }
}

const setLuckyNumbers = (luckyNumberObj: ILuckyNumber) => {
    return {
        type: ActionKeys.SET_LUCKY_NUMBERS,
        value: luckyNumberObj,
    }
}

const setPopupLoginFlag = (flag: boolean) => {
    return {
        type: ActionKeys.SET_POPUP_LOGIN_FLAG,
        value: flag,
    }
}

const setUser = (user: IUser) => {
    return {
        type: ActionKeys.SET_USER,
        value: user,
    }
}

const setLoginTime = () => {
    return {
        type: ActionKeys.SET_LOGIN_TIME
    }
}

const setToken = (token: string) => {
    return {
        type: ActionKeys.SET_TOKEN,
        value: token,
    }
}

const setOldResults = (oldResults: IRoundResult[]) => {
    return {
        type: ActionKeys.SET_OLD_RESULTS,
        value: oldResults,
    }
}

const setNanoStreamToken = (token: string) => {
    return {
        type: ActionKeys.SET_NANO_STREAM_TOKEN,
        token: token,
    }

}

export default {
    setOldResults,
    updateDevice,
    updateDimensions,
    reBet,
    doubleBet,
    undoBet,
    addBets,
    setSetup,
    setSelectedChip,
    setRound,
    setRoundStatus,
    clearBets,
    setResult,
    setPopupMessage,
    setPopupFlag,
    addBetAction,
    setFullScreen,
    setLuckyNumbers,
    setPopupLoginFlag,
    setUser,
    setLoginTime,
    setToken,
    setNanoStreamToken,
};
