import React, { ReducerState, useEffect } from 'react';
import { connect } from 'react-redux'
import SyncActions from '../redux/actions/SyncActions'
import { ReduxStateType } from '../redux/reducers/Reducers'
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ReducersGeneralType } from '../redux/reducers/ReducersGeneral';
import { ReducersGameType } from '../redux/reducers/ReducersGame';
import Tools from '../tools/Tools';
import { DeviceKeys, ActionKeys, PopupKeys } from '../tools/Keys';
import { useHistory, withRouter, RouteComponentProps, useParams } from 'react-router';
import { History } from 'history';
import socketService from '../services/SocketService';
import AsyncActions from '../redux/actions/AsyncActions';

export interface InitinalPageProps {

}

interface StateToPropsType {
    general: ReducersGeneralType,
    game:ReducersGameType,
}

interface DispatchToPropsType {
    updateDevice: typeof SyncActions.updateDevice,
    setSetup: typeof SyncActions.setSetup,
    initiateUser: Function,
    postBets:Function,
    setResult:Function,
    clearBets:Function,
    setLuckyNumbers: typeof SyncActions.setLuckyNumbers;
    setRound: typeof SyncActions.setRound,
    setRoundStatus: typeof SyncActions.setRoundStatus,
    setPopupLoginFlag: typeof SyncActions.setPopupLoginFlag,
    setPopupFlag: typeof SyncActions.setPopupFlag,
    setUser: typeof SyncActions.setUser,
    setLoginTime: typeof SyncActions.setLoginTime,
    setToken: typeof SyncActions.setToken,
}

type Props =
    InitinalPageProps &
    StateToPropsType &
    DispatchToPropsType &
    RouteComponentProps;

const useStyles = makeStyles((theme: Theme) => ({
    /*root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    paper: {

    }*/
}));

// It is async for futere use
const initinalProcess = async (props: Props) => {
    // Start - Init
    props.updateDevice(Tools.isMobile() ? DeviceKeys.MOBILE : DeviceKeys.DESKTOP);
    
    //get user by token
    const query = new URLSearchParams(props.location.search);    
    const token = query.get('token')    
    if(token){
        socketService.emitLoginByToken(token);
    } 
    // TODO : implement reddirect logic also if token is not valid 
    else {
        window.location.href = `${Tools.getBaseUrl()}auth/login`;
    }

    /*
    // Popup login
    if (!props.game.user._id) {
        // TODO check local storage
        // Set a unique key for login requset state -> to true
        props.setPopupLoginFlag(true);
        // Set the popup show to true
        props.setPopupFlag(PopupKeys.MESSAGE, true);
    }
    */

    // props.initiateUser(); // we shell use socket instead TODO remove this 
    
    // End - Init
    // Approve & Nav to GamePage
    props.setSetup(true);
    props.history.push('/play');

    
};

const InitinalPage: React.FC<Props> = (props) => {
    const classes = useStyles();    
    useEffect(() => {
        initinalProcess(props);
    }, []);

    return (
        <div>
            Loading ...
        </div>
    )
}

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        general: {...state.general},
        game: {...state.game},
    }
}

const mapDispatchToProps = {
    updateDevice: SyncActions.updateDevice,
    initiateUser: AsyncActions.initiateUser,
    setSetup: SyncActions.setSetup,
    setRound: SyncActions.setRound,
    setRoundStatus: SyncActions.setRoundStatus,
    postBets:AsyncActions.postBets,
    setResult:SyncActions.setResult,
    clearBets:SyncActions.clearBets,
    setLuckyNumbers: SyncActions.setLuckyNumbers,
    setPopupLoginFlag: SyncActions.setPopupLoginFlag,
    setPopupFlag: SyncActions.setPopupFlag,
    setUser: SyncActions.setUser,
    setLoginTime: SyncActions.setLoginTime,
    setToken: SyncActions.setToken,
};

export default connect<
StateToPropsType,
DispatchToPropsType,
InitinalPageProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(InitinalPage));
