import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { PlenikaTheme } from '../CustomThemeProvider';
import SyncActions from '../../redux/actions/SyncActions';
import { ReduxStateType } from '../../redux/reducers/Reducers';
import { ReducersGameType } from '../../redux/reducers/ReducersGame';
import xIcon from '../../assests/icon-x.png';
import { PopupKeys } from '../../tools/Keys';
import { TextField, Button } from '@material-ui/core';
import socketService from '../../services/SocketService';
import { IUser } from 'plenika-types';

export interface MessageDialogProps {
}

interface StateToPropsType {
    game: ReducersGameType;
}

interface DispatchToPropsType {
    setPopupFlag: typeof SyncActions.setPopupFlag;
    setPopupMessage: typeof SyncActions.setPopupMessage;
}

type Props = MessageDialogProps & StateToPropsType & DispatchToPropsType;

const useStyles = makeStyles((theme: PlenikaTheme) => {
    const width = theme.general.isMobile ? 43 : 31.5;
    const height = theme.general.isMobile ? 41.6 : 27.7;
    return {
        ultraFrame: {
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 5000,
            textAlign:"center",
        },
        backgroundNone: {
            pointerEvents: 'none',
        },
        frame: {
            width: theme.units.w(width),
            height: theme.units.h(height),
            background: 'linear-gradient(90deg, #363232AA 0%, #141212AA 100%)',
            borderRadius: theme.units.h(2),
            border: `${Math.max(theme.units.h(0.05), 0.5)}px solid white`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'auto',
            position: 'relative',
            marginBottom: `${theme.units.h(10)}px`
        },
        textFrame: {
            fontSize: theme.units.h(2.5),
            color: 'white',
        },
        closeButton: {
            position: 'absolute',
            width: theme.units.h(4),
            height: theme.units.h(4),
            top: 0,
            right: 0,
        },
        inputLine: {
            textAlign: 'center',
            margin: theme.units.h(0.5),
        }
    };
});

const MessageDialog: React.FC<Props> = (props) => {
    const classes = useStyles();

    const [stateUser, setStateUser] = useState<IUser>({
        balance: -1,
        username: '',
        password: '',
    } as IUser);

    const popupContent = props.game.popupLogin ? (
        <div>
            <div className={classes.inputLine}>
                <TextField
                    id="username"
                    label="Username"
                    onChange={e => setStateUser({ ...stateUser, username: e.target.value })}
                />
            </div>
            <div className={classes.inputLine}>
                <TextField
                    type="password"
                    id="passwrd"
                    label="Password"
                    onChange={e => setStateUser({ ...stateUser, password: e.target.value })}
                />
            </div>
            <div className={classes.inputLine}>
                <Button onClick={() => socketService.emitLogin(stateUser)}>
                    Login
                </Button>
            </div>
        </div>
    ) : (
            <>
                {props.game.popupMessage.split('\n').map(x => <div key={"popup_message_part_" + x.toString()}>{x}</div>)}
            </>
        );

    return (
        <div className={[classes.ultraFrame,
        props.game.popupsState[PopupKeys.MESSAGE] && props.game.popupLogin ? '' : classes.backgroundNone
        ].join(' ')}>
            {props.game.popupsState[PopupKeys.MESSAGE] ?
                <div className={classes.frame}>
                    <div className={classes.textFrame}>
                        {popupContent}
                    </div>
                    <img
                        className={classes.closeButton}
                        src={xIcon}
                        onClick={() => props.setPopupFlag(PopupKeys.MESSAGE, false)}
                    />
                </div> : null
            }
        </div>
    );
}

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        game: { ...state.game },
    }
}

const mapDispatchToProps = {
    setPopupFlag: SyncActions.setPopupFlag,
    setPopupMessage: SyncActions.setPopupMessage,
};

export default connect<
    StateToPropsType,
    DispatchToPropsType,
    MessageDialogProps,
    ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(MessageDialog);
