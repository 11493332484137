
const saveToken = (token: string) => {
    localStorage.setItem('token', token);
}

const loadToken = () => {
    return localStorage.getItem('token') as string;
}

const removeToken = () => {
    localStorage.removeItem('token');
}

export default {
    saveToken,
    loadToken,
    removeToken,
}