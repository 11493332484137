import React, { useEffect } from 'react';
import videoConfig, { videoSettings } from '../tools/VideoConfig'
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    playerRoot: {
        height: '100%',
        width: '100%',
    },
}));

export interface VideoPlayerProps {
    id: string;
    stream_id: string;
}

type Props = VideoPlayerProps;

const VideoPlayer: React.FC<Props> = (props) => {
    const classes = useStyles();

    useEffect(() => {
        // When after component loaded first time
        try {

            const script = document.createElement("script");
            script.src = videoSettings.url;
            // script.async = videoSettings.async;
            script.onload = () => scriptLoaded(props.id, props.stream_id);
            document.body.appendChild(script);
        } catch (e) {
            throw "Error in Videolayer " + e;
        }
    }, []);

    return (
        <div id={props.id} className={classes.playerRoot} />
    )
}

const scriptLoaded = async (id: string, stream_id: string) => {
    try {
        let player = new window.NanoPlayer(id);
        await player.setup(videoConfig(stream_id));
    } catch (e) {
        throw e;
    }
}

export default VideoPlayer;
