export const ActionKeys = Object.freeze({
    // Async Action
    INTIATE_USER: 'INTIATE_USER',

    // Sync Action
    UPDATE_DEVICE: 'UPDATE_DEVICE',
    UPDATE_DIMENSIONS: 'UPDATE_DIMENSIONS',
    UPDATE_ROUND:'UPDATE_ROUND',
    SET_ROUND_STATUS:'SET_ROUND_STATUS',
    ADD_BETS: 'ADD_BETS',
    UNDO_BET: 'UNDO_BET',
    RE_BET: 'RE_BET',
    DOUBLE_BET: 'DOUBLE_BET',
    SET_SETUP: 'SET_SETUP',
    SET_SELECTED_CHIP: 'SET_SELECTED_CHIP',
    POST_BETS:'POST_BETS',
    CLEAR_BETS:'CLEAR_BETS',
    SET_RESULT:'SET_RESULT',
    SET_POPUP_FLAG: 'SET_POPUP_FLAG',
    SET_POPUP_MESSAGE: 'SET_POPUP_MESSAGE', 
    ADD_BET_ACTION: 'ADD_BET_ACTION',
    SET_FULL_SCREEN: 'SET_FULL_SCREEN',
    SET_LUCKY_NUMBERS: 'SET_LUCKY_NUMBERS',
    SET_POPUP_LOGIN_FLAG: 'SET_POPUP_LOGIN_FLAG',
    SET_USER: 'SET_USER',
    SET_LOGIN_TIME: 'SET_LOGIN_TIME',
    SET_TOKEN: 'SET_TOKEN',
    POST_BALANCE_RESET:'POST_BALANCE_RESET',
    SET_OLD_RESULTS: 'SET_OLD_RESULTS',

    SET_NANO_STREAM_TOKEN:'SET_NANO_STREAM_TOKEN'
});

export const ActionStatusKeys = Object.freeze({
    START: 'START',
    FAIL: 'FAIL',
    SUCCESS: 'SUCCESS',
});

export const DeviceKeys = Object.freeze({
    MOBILE: 'MOBILE',
    DESKTOP: 'DESKTOP',
});

export const GameMods = Object.freeze({
    PLACE_BETS: 'PLACE_BETS',
    NO_BETS: 'NO_BETS',
});

export const GameSubMods = Object.freeze({
    TIME_TO_PLACE: 'TIME_TO_PLACE',
    LAST_BETS: 'LAST_BETS',
    BETS_CLOSING: 'BETS_CLOSING',

    BETS_CLOSED: 'BETS_CLOSED',
    GAME_ROUND: 'GAME_ROUND',
    NO_MORE_BETS: 'NO_MORE_BETS',
})

export const ResponseMessages = Object.freeze({
    CONNECTION_TO_SERVER_FAILD: 'CONNECTION_TO_SERVER_FAILD',
});

export const BetTags = Object.freeze({
    RE_BET: 'RE_BET',
    DOUBLE_BET: 'DOUBLE_BET', 
});

export const PopupKeys = Object.freeze({
    MESSAGE: 'MESSAGE',
    TABLE: 'TABLE',
    BALANCE: 'BALANCE',
});