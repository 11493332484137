import { IRequest, IResponse } from 'plenika-types';
import Tools from '../tools/Tools';
import { ResponseMessages } from '../tools/Keys';
import LocalStorageService from './LocalStorageService';

const sendRest = async (path: string = '', request?: IRequest | null, method: string = 'post') => {
    console.log('send with token : ', LocalStorageService.loadToken())
    try {
        const response = await fetch(Tools.getBaseUrl() + path, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'token': LocalStorageService.loadToken(),
            },
            body: request ? JSON.stringify(request) : undefined,
        });
        return await response.json();
    } catch (err) {
        console.log(err);
        return {
            success: false,
            message: ResponseMessages.CONNECTION_TO_SERVER_FAILD,
        } as IResponse;
    }
};

export default {
    sendRest,
};