import React, { useRef, useEffect, MutableRefObject } from 'react';
import { ReactComponent as MobileBettingGridSvg } from '../../assests/Grid_Mobile.svg';
import { ReactComponent as DescktopBettingGridSvg } from '../../assests/Grid_Desktop.svg';
import { ReduxStateType } from '../../redux/reducers/Reducers';
import { connect } from 'react-redux';
import { DeviceKeys, PopupKeys } from '../../tools/Keys';
import { makeStyles } from '@material-ui/styles';
import { withContentRect, MeasuredComponentProps } from 'react-measure';

import SyncActions from '../../redux/actions/SyncActions';
import { BetAction } from '../../redux/reducers/ReducersGame';
import Tools from '../../tools/Tools';
import { Bet } from 'plenika-types';
import { PlenikaTheme } from '../CustomThemeProvider';
import useGradient, {generateGradient} from '../hooks/UseGradient';
import MobileBettingGrid from './MobileBettingGrid';

export interface BetGridCoreProps {

}

interface StateToPropsType {
    device: string;
    betActions: BetAction[];
    selectedChip: number;
    canBet: boolean;
}

interface DispatchToPropsType {
    addBets:           typeof SyncActions.addBets,
    setPopupFlag:      typeof SyncActions.setPopupFlag,
    setPopupMessage:   typeof SyncActions.setPopupMessage,
}

const useStyles = makeStyles((theme: PlenikaTheme) => {
    const gradient = generateGradient(['stroke'], 'borderAnim');
    return {
        gridSVG: {
            width: '100%',
            top: '0',
            left: '0',
        },
        absolute: {
            position: 'absolute',
        },
        gridFrame: {
            width: '100%',
            position: 'relative',
            textAlign: 'center',
        },
        nonClickable: {
            pointerEvents:'none',
        },
        svgText: {
            fill: 'white',
            userSelect: 'none',
        },
        ...gradient,
    }
});

type Props = BetGridCoreProps & StateToPropsType & DispatchToPropsType & MeasuredComponentProps;

const betIdHoverIdMap : {[key: string]: string} = {
    'th_2to1_c_1_'  : 'h_2to1_c',
    'th_2to1_b_1_'  : 'h_2to1_b',
    'th_2to1_a_1_'  : 'h_2to1_a',
    'th_3rd12_1_'   : 'h_3rd12',
    'th_2nd12_1_'   : 'h_2nd12',
    'th_1to12_1_'   : 'h_1to12',
    'th_red_1_'     : 'h_red',
    'th_black_1_'   : 'h_black',
    'th_odd_1_'     : 'h_odd',
    'th_even_1_'    : 'h_even',
    'th_19to36_1_'  : 'h_19to36',
    'th_1to18_1_'   : 'h_1to18',
};

const BetGridCore: React.FC<Props> = (props) => {
    const classes = useStyles();
    const isMobile = props.device === DeviceKeys.MOBILE;
    const gridSvgRef = useRef(null);
    
    const isOpen = props.canBet;

    useEffect(() => {
        if (isMobile) {
            return;
        }
        // @ts-ignore
        (gridSvgRef.current as HTMLElement).addEventListener("mousemove", ( event: MouseEvent ) => {
            updateHovers(gridSvgRef, event);
        });
        // @ts-ignore
        (gridSvgRef.current as HTMLElement).addEventListener("mouseleave", (event: MouseEvent) => {
            updateHovers(gridSvgRef, event);
        });
    }, []);

    useEffect(()=> {
        updateHovers(gridSvgRef);
    }, [isOpen]);

    const popupMessage = (message: string) => {
        props.setPopupMessage(message);
        props.setPopupFlag(PopupKeys.MESSAGE, true);
    };

    const clickRect = (ev: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        if (!isOpen) return;
        // Start Initiate Process
        const elementSvg = ev.target as SVGSVGElement;
        const betAmount = props.selectedChip;
        //@ts-ignore
        let betId = getSVGId(elementSvg); 
        if (!(betId && betId.startsWith('th_'))) return;
        // End Initiate Process
        
        // Dispatch
        props.addBets([{
            betAmount: betAmount,
            betId: betId,
        }]);

        setTimeout(() => {
            props.setPopupFlag(PopupKeys.MESSAGE,false);
            props.setPopupMessage('');
        }, 3000);
    }

    const grid = isMobile ? (
        <MobileBettingGrid      ref={gridSvgRef}    className={classes.gridSVG + (isOpen ? ' is_open ' : '' )} onClick={clickRect}/>
    ) : (
        <DescktopBettingGridSvg ref={gridSvgRef}    className={classes.gridSVG + (isOpen ? ' is_open ' : '' )} onClick={clickRect}/>
    );

    const chips = [];
    if (props.contentRect.bounds && gridSvgRef.current) {
        const betsOnGrid: {[betId: string]: number} = {};
        // Order all user bet actions to simple view of the data
        for (const actionBet of props.betActions) {
            if (!actionBet.active) {
                continue;
            }
            for (const roundBet of actionBet.bets) {
                let amount = betsOnGrid[roundBet.betId] || 0;
                amount += roundBet.betAmount;
                betsOnGrid[roundBet.betId] = amount;
            }
        }
        // Create the chips on screen
        for (const betId of Object.keys(betsOnGrid)) {
            //@ts-ignore
            const svgById = gridSvgRef.current.getElementById(betId) as SVGElement;
            //@ts-ignore
            const bounds = svgById.getBBox();
            const rectBounds = {
                x: bounds.x,
                y: bounds.y,
                width: bounds.width,
                height: bounds.height,
            };
            if (idIsTopRow(betId)) {
                rectBounds.y += 15.5;
                rectBounds.height -= 15.5;
            }
            if (idIsRightColumn(betId)) {
                rectBounds.width -= 33.14;
            }

            const chipSize = 75;
            let centerX = rectBounds.x + (rectBounds.width/2);
            let centerY = rectBounds.y + (rectBounds.height/2);

            const rectX = centerX - chipSize/2;
            const rectY = centerY - chipSize/2;

            const fontSize = chipSize / 2;
            const amount = betsOnGrid[betId];

            chips.push(
                <g key={betId} transform={svgById.getAttribute('transform') as string}>
                    <image
                        x={rectX}
                        y={rectY}
                        width={chipSize}
                        height={chipSize}
                        href={Tools.getChipSrc(amount)}
                        />
                    <text
                        x={centerX}
                        y={centerY + (fontSize/4)}
                        width={chipSize}
                        textAnchor="middle"
                        fontFamily={"open sans"}
                        className={classes.svgText}
                        style={{fontSize: fontSize}}
                        >
                        {amount/100}
                    </text>
                </g>
            );
        }
    }
    return (
        <div ref={props.measureRef} className={classes.gridFrame}>
                {grid}
                <svg className={[classes.gridSVG, classes.nonClickable, classes.absolute].join(' ')}
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox={isMobile ? "0 0 1817.82 540.84" : "0 0 1822 488"}
                    >
                    {chips}
                </svg>
        </div>
    )
}

const updateHovers = (gridSvgRef: MutableRefObject<null | HTMLElement>, event?: MouseEvent) => {
    const isOpen = gridSvgRef.current && (gridSvgRef.current as HTMLElement).classList.contains('is_open');
    // console.log({isOpen,gridSvgRef, c: gridSvgRef.current })
    let betId: string | null = null;
    // Get id and numbers ready
    let numbers: number[] = [];
    if (isOpen && event && event.target) {
        betId = getSVGId(event.target as HTMLElement);
    }
    if (betId) {
        numbers = new Bet({
            amount: 1,
            name: betId,
            balance: 0,
        }).numbers;
        if (!numbers) { 
            numbers = [];
        }
    }
    // TODO ask adi why numbers type of number[] and not ?number[], ask him to remove the console.log inside
    
    // Hover Loop (Update hover state)
    // @ts-ignore
    const hovers: HTMLElement[] = (gridSvgRef.current as HTMLElement).querySelectorAll("[id^='h_']");
    for (const hover of hovers) {
        const shortHoverId = hover.id.substr(2);
        const includeZero = !!numbers.includes(0);
        if (
            (
                numbers.find( i => String(i) === shortHoverId)
            ) || (
                betId && 
                betIdHoverIdMap[betId] 
                && betIdHoverIdMap[betId].substr(2) === shortHoverId 
            ) || (
                includeZero && shortHoverId === '0'
            )
        ) {
            hover.classList.add('hover');
        } else {
            hover.classList.remove('hover');
        }
    }
}

const getSVGId = (element: HTMLElement) => {
    let betId = element.id ? element.id : null;
    if (!betId && element.parentNode) {
        betId = (element.parentNode as HTMLElement).id;
    }
    return betId;
}

const idIsTopRow = (id: string) => {
    const chipNumbers = getChipNumbers(id);
    return chipNumbers.length > 0 && chipNumbers.length < 3 &&
        Number(chipNumbers[0])%3 === 0;
}

const idIsRightColumn = (id: string) => {
    const chipNumbers = getChipNumbers(id);
    return chipNumbers.length > 0 &&
        Number(chipNumbers[0])-33 > 0;
}

const getChipNumbers = (id: string) => {
    return id.slice(3).split("_");
}

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        device: state.general.device,
        betActions: state.game.betActions, /*Do not change the array from here */
        selectedChip: state.game.selectedChip,
        canBet: state.game.round.current_state.can_bet,
    }
}

const mapDispatchToProps = { 
    addBets:            SyncActions.addBets,
    setPopupFlag:       SyncActions.setPopupFlag,
    setPopupMessage:    SyncActions.setPopupMessage, 
};

export default connect<
StateToPropsType,
DispatchToPropsType,
BetGridCoreProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(withContentRect('bounds')(BetGridCore));
