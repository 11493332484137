import { CSSProperties } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { ReduxStateType } from "../../redux/reducers/Reducers";
import Tools from "../../tools/Tools";

interface GradientProps {
    canBet: boolean;
    startTime: number;
}

const GREEN =   '#47b259';
const YELLOW =  '#fbb500';
const WHITE =   '#FFFFFF';

export const Colors = Object.freeze({
    GREEN: GREEN,
    YELLOW: YELLOW,
    WHITE: WHITE,
});

export const colorPropertyByKeys = (keys: (keyof CSSProperties)[], color: any) => {
    return keys.map(k => ({[k]: color})).reduce((item, sum) => ({...sum, ...item}), {}) as CSSProperties;
}  

export const generateGradient = (keys: (keyof CSSProperties)[], animationName: string, overideKeyFrame?: any) => {
    return {
        gradientAnim: {
            animationName: `$${animationName}!important`,
        },
        [`@keyframes ${animationName}`]: (overideKeyFrame !== undefined ? overideKeyFrame : {
            '0%':       colorPropertyByKeys(keys, GREEN),
            '99.9999%': colorPropertyByKeys(keys, YELLOW),
            '100%':     colorPropertyByKeys(keys, WHITE),
        }),
    }
};

const useGradient = () => {
    const {canBet, startTime} = useSelector<ReduxStateType, GradientProps>(state => ({
        canBet: state.game.round.current_state.can_bet,
        startTime: state.game.round.time_started_unix,
    }), (prevProps, nextProps) => 
        prevProps.canBet === nextProps.canBet && prevProps.startTime === nextProps.startTime
    );
    const diffrenceSeconds = Tools.secondsDifference(startTime);
    return {
        style: {
            animation: '18s',
            animationIterationCount: '1',
            animationDelay: `-${diffrenceSeconds}s`,
        },
        canBet: canBet,
    }
}

export default useGradient;