import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import RoateScreenSRC from '../assests/rotate_device.png'

export interface RotateScreenProps {

}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        backgroundColor: '#0d0000', // TODO use theme 
        alignItems: 'center',
        justifyContent: 'center',
    },
    landscapeIcon: {
        maxWidth: '50%', 
        maxHeight: '50%',
        filter: 'brightness(0) invert(1)',
    },
    innerContainer: {
        textAlign: 'center',
        color: 'white',
    }
}));

type Props = RotateScreenProps;

const RotateScreen: React.FC<Props> = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.innerContainer}>
                <img
                        className={classes.landscapeIcon}
                        src={RoateScreenSRC}
                />
                <div>Please rotate your device</div>
            </div>
        </div>
    );
} 

export default RotateScreen;