"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var luckyNumberValues = [{
  index: 1,
  luckyNumbers: [9, 29, 14],
  spinResult: 10
}, {
  index: 2,
  luckyNumbers: [5, 8],
  spinResult: 12
}, {
  index: 3,
  luckyNumbers: [28, 0, 6],
  spinResult: 30
}, {
  index: 4,
  luckyNumbers: [4, 28, 36],
  spinResult: 7
}, {
  index: 5,
  luckyNumbers: [15],
  spinResult: 15
}, {
  index: 6,
  luckyNumbers: [12, 11],
  spinResult: 0
}, {
  index: 7,
  luckyNumbers: [10, 4, 22],
  spinResult: 22
}, {
  index: 8,
  luckyNumbers: [1, 30],
  spinResult: 19
}, {
  index: 9,
  luckyNumbers: [22, 23, 17, 0, 14],
  spinResult: 22
}, {
  index: 10,
  luckyNumbers: [2, 9, 17, 32],
  spinResult: 2
}];
exports.default = luckyNumberValues;

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}
/**
 *
 * @param index should be a number with value of 1 to 10
 */


function getLuckyNumbers(index) {
  var arrMultiplicationValues = [50, 100, 200, 300, 400, 500];
  var roundResults = luckyNumberValues.filter(function (l) {
    return l.index === index;
  })[0];
  var arrMultiplicationLN = [];

  for (var i = 0; i < roundResults.luckyNumbers.length; i++) {
    var mIndex = getRandomInt(0, arrMultiplicationValues.length - 1);
    var mResult = arrMultiplicationValues[mIndex];
    arrMultiplicationLN.push(mResult);
  }

  var result = {
    index: index,
    luckyNumbers: roundResults.luckyNumbers,
    spinResult: roundResults.spinResult,
    multiplication: arrMultiplicationLN
  };
  return result;
}

exports.getLuckyNumbers = getLuckyNumbers;