import React, { useState } from 'react';
import { makeStyles, CSSProperties } from '@material-ui/styles';
import { ReducersGameType } from '../../redux/reducers/ReducersGame';
import { ReducersGeneralType } from '../../redux/reducers/ReducersGeneral';
import { ReduxStateType } from '../../redux/reducers/Reducers';
import { connect } from 'react-redux';
import SyncActions from '../../redux/actions/SyncActions';
import { PlenikaTheme } from '../CustomThemeProvider';
import undoIcon from '../../assests/undo-3.png';
import { DeviceKeys } from '../../tools/Keys';
import GridButton from './GridButton';
import racetrackWhite from '../../assests/racetrack-white.svg';
import iconRacetrack from '../../assests/icon-racetrack.svg';
import iconFav from '../../assests/icon-fav.svg';
import iconAutobet from '../../assests/icon-autobet.svg';
import iconChat from '../../assests/icon-chat.svg';
import VerticalButton from './VerticalButton';

// TODO COPY FROM BET GRID, SET TO TOOLS
const MOBILE_TOP = 15;
const MOBILE_MIDDLE = 55;
const MOBILE_BOTTOM = 100 - MOBILE_TOP - MOBILE_MIDDLE;

const DESKTOP_TOP = 18;
const DESKTOP_MIDDLE = 66.5;
const DESKTOP_BOTTOM = 100 - DESKTOP_TOP - DESKTOP_MIDDLE;

const D_BAR_HEIGHT = 25;

const D_BAR_BACKGROUND = '#0d0100DD';
const D_CENTER_BACKGROUND = '#0d0100BB';
// TODO COPY FROM BET GRID, SET TO TOOLS

const useStyles = makeStyles((theme: PlenikaTheme) => ({
    sideGridBar: {
        height: theme.units.h(D_BAR_HEIGHT),
        backgroundColor: D_BAR_BACKGROUND,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    chatBarFrame: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        height: theme.units.h(6),
    },
    neighborsFrame: {
        width: '100%', 
        display: 'flex',
        height: theme.units.h(D_BAR_HEIGHT-11),
    },
    bottomNavFrame: {
        width: '100%',
        height: theme.units.h(5),
        display: 'flex',
    },
    sideButton: {
        fontSize: `${theme.units.h(2)}px`,
        padding: `${theme.units.h(0.5)}px`,
        color: 'white',
    },
    chatIcon: {
        width: theme.units.h(3),
        hidth: theme.units.h(3),
    },
    chatIconImg: {
        maxWidth: '100%',
        maxHeight: '100%',
        height: '100%',
    },
    chatBar: {
        border: `${Math.max(theme.units.h(0.1), 0.5)}px white solid`,
        borderRadius: `${theme.units.h(1.5)}px`,
        height: `${theme.units.h(3)}`,
        fontSize: `${theme.units.h(2)}px`,
        backgroundColor: '#FFFFFF11',
        color: 'white',
    },
    leftPad: {
        paddingLeft: theme.units.h(2),
    },
}));

export interface NeighborsProps {
};


interface StateToPropsType {
}

interface DispatchToPropsType {
}

type Props = NeighborsProps & StateToPropsType & DispatchToPropsType;
  
const Neighbors: React.FC<Props> = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.sideGridBar} style={{flex: 3}}>
            <div className={[classes.chatBarFrame, classes.leftPad].join(' ')}>
                <div className={classes.chatBar} style={{flex: 10}}>
                    Click to chat
                </div>
                <div className={classes.chatIcon} style={{flex: 1}}>
                    <img className={classes.chatIconImg} src={iconChat}/>
                </div>
            </div>
            <div className={[classes.neighborsFrame, classes.leftPad].join(' ')}>
                <div style={{flex: 10}}>
                    <img style={{width: '100%'}} src={racetrackWhite}/>
                </div>
                <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <div className={classes.sideButton}>
                        +
                    </div>
                    <div className={classes.sideButton}>
                        2
                    </div>
                    <div className={classes.sideButton}>
                        -
                    </div>
                </div>
            </div>
            <div className={[classes.bottomNavFrame, classes.leftPad].join(' ')}>
                <div style={{flex: 1, textAlign: 'center'}}>
                    <VerticalButton text={'FAVORITE BETS'} icon={iconFav}/>
                </div>
                <div style={{flex: 1, textAlign: 'center'}}>
                    <VerticalButton text={'AUTOBET'} icon={iconAutobet}/>
                </div>
                <div style={{flex: 1, textAlign: 'center'}}>
                    <VerticalButton text={'RACETRACK'} icon={iconRacetrack}/>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        
    }
}

const mapDispatchToProps = { 

 };

export default connect<
StateToPropsType,
DispatchToPropsType,
NeighborsProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(Neighbors);
