import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { withContentRect, MeasuredComponentProps } from 'react-measure';

export type OnSizeChange = (
    width: number,
    height: number,
    componentWidth: number,
    componentHeight: number,
    sideBarWidth: number,
) => void;

export interface ContainerProps {
    ratio: number[];
    maxHeight: number;
    maxWidth: number;
    onSizeChange?: OnSizeChange;
    sideBar?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        backgroundColor: 'black',
    },
    container: {
        backgroundColor: '#0d0100',
        position:'relative',
    },
    innerContainer: {
        height: '100%',
    },
    sideBar: {
        height: '100%',
        backgroundColor: '#0d0100',
    },
    flexAuto: {
        flex: 'auto',
    }
}));

type Props = ContainerProps & MeasuredComponentProps;

const RaioContainer: React.FC<Props> = (props) => {
    const classes = useStyles();
    // Get vars ready
    const showSideBar = !!props.sideBar;
    let width = 0;
    let height = 0;
    let screenWidth = 0;
    let screenHeight = 0;
    let sideBarWidth = 0;
    let margingWidth = 0;
    let fullContainerWidth = 0;
    if (props.contentRect.bounds) {
        // Get the height and width
        screenWidth = props.contentRect.bounds.width;
        screenHeight = props.contentRect.bounds.height;
        sideBarWidth = 0;

        // Calculate ratio without bar (First calculate if for gathering details)
        [width, height] = ratioCalculator(
            props.ratio, 
            screenWidth, 
            screenHeight, 
            props.maxWidth, 
            props.maxHeight
        );

        if (showSideBar) {
            sideBarWidth = width / 20;
            let [w,h] = ratioCalculator(
                props.ratio,
                screenWidth - sideBarWidth,
                screenHeight,
                props.maxHeight,
                props.maxWidth,
            );
            width = w;
            height = h;
        }

        // In case we are having a side bar
        // We need to calculate the exact "marging" width
        margingWidth = (screenWidth - width - sideBarWidth) / 3;
        fullContainerWidth = showSideBar ? width + margingWidth + sideBarWidth : width;
    }

    useEffect(() => {
        if (!props.contentRect.bounds) {
            return;
        }
        props.onSizeChange && props.onSizeChange(
            width,
            height,
            screenWidth,
            screenHeight,
            sideBarWidth,
        );
    }, [width, height, screenWidth, screenHeight, sideBarWidth]);

    if (props.contentRect.bounds) {
        return (
            <div ref={props.measureRef} className={classes.root}>
                <div className={classes.container}
                    style={{
                        width: fullContainerWidth,
                        height: height,
                        display: 'flex',
                    }}
                >
                    <div className={classes.innerContainer} style={{width: width}}>
                        {props.children}
                    </div>
                    { showSideBar ? (
                        <>
                            <div className={classes.flexAuto}/>
                            <div className={classes.sideBar} style={{width: sideBarWidth}}>
                                {props.sideBar}
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        );
    } else {
        return (
            <div>Loading Screen...</div>
        )
    }
}

const ratioCalculator = (ratio: number[], screenWidth: number, screenHeight: number, maxWidth?: number, maxHeight?: number) => {
    let height = screenHeight;
    let width = screenWidth;
    if (maxHeight && height > maxHeight) {
        height = maxHeight;
    }
    if (maxWidth && width > maxWidth) {
        width = maxWidth;
    }
    const widthRatioToHeight = height * (ratio[0]/ratio[1]);
    if (widthRatioToHeight < width) {
        width = widthRatioToHeight;
    }
    const heightRatioToWidth = width * (ratio[1]/ratio[0]);
    if (heightRatioToWidth < height) {
        height = heightRatioToWidth;
    }
    return [width, height];
}

export default withContentRect('bounds')(RaioContainer);
