import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { Dimensions } from '../../redux/reducers/ReducersGeneral';
import { ReducersGameType } from '../../redux/reducers/ReducersGame';
import { ReduxStateType } from '../../redux/reducers/Reducers';
import { connect } from 'react-redux';
import { ReducersThemeType } from '../../redux/reducers/ReducersTheme';
import { PlenikaTheme } from '../CustomThemeProvider';
import useGradient, { Colors, generateGradient } from '../hooks/UseGradient';
import { getTotalTime, showTimerStates, gameStates } from 'plenika-types';
import LinearColorInterpolator, { Color } from '../../services/ColorService';

export interface TimerProps {
}

interface StateToPropsType {
    time_started_unix: number;
    currentStateName: string;
    dimentions?: Dimensions;
    theme:ReducersThemeType;
}

interface DispatchToPropsType {

}

type Props = TimerProps & StateToPropsType & DispatchToPropsType;

const useStyles = makeStyles((theme: PlenikaTheme) => {
    const gradient = generateGradient(['color'], 'timerAnim');
    return {
        timerWrapper: {
            display:'inline-block',
            flexDirection: 'column',
            fontFamily: "'Open Sans', sans-serif",
            paddingLeft: '2%',
            paddingRight: '2%',
            textAlign: 'center',
            color: Colors.GREEN,
            verticalAlign: 'center',
            fontSize: theme.units.h(theme.general.isMobile?6:3),
        },
        ...gradient,
    }
});

const Timer: React.FC<Props> = (props) => {
    const height = props.dimentions ? props.dimentions.height : 0;
    const classes = useStyles();
    
    const [ flag, forceUpdate] = useState(true);
    
    // TODO this useeffect call evry time comp change - there is better solution ? 
    useEffect(() => {
        const intervalId = setInterval(()=> {
            forceUpdate(!flag);
        }, 100);
        return () => clearInterval(intervalId);
    });

    const currentTime = new Date().getTime();
    const roundTime:number = getTotalTime(showTimerStates);
    const stampMinusStarted:number = roundTime - currentTime + props.time_started_unix;
    const roundTotalTime:number = getTotalTime(showTimerStates);
    const totalStampMinusStarted:number = roundTotalTime - currentTime + props.time_started_unix;
    const timeLeft:number = Math.floor(stampMinusStarted/1000);
    const totalTimeLeft:number = Math.floor(totalStampMinusStarted/1000);
    const percentageLeft:number = stampMinusStarted/roundTime;
    const totalPercentageLeft:number = totalStampMinusStarted/roundTotalTime;
    const r = new Color({ r:27, g:161, b:101 });
    const l = new Color({ r:246, g:185, b:99 });
    const barColor:string = LinearColorInterpolator.findColorBetween(l, r, totalPercentageLeft*100).asRgbCss();
    const showProgressBar:boolean = 
        showTimerStates.indexOf(props.currentStateName) > -1 &&
        totalTimeLeft >= 0 &&
        totalTimeLeft <= roundTotalTime;
    const showTimerBar:boolean = 
        showTimerStates.indexOf(props.currentStateName) > -1 &&
        timeLeft >= 0 &&
        timeLeft <= roundTime;
    
    return (
        <>
        {
            showProgressBar &&
            <div className={[
                classes.timerWrapper, 
            ].join(' ')}
            style={{color: barColor}}>
                {timeLeft}
            </div>
        }
        </>
    );
}

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        time_started_unix: state.game.round.time_started_unix,
        currentStateName: state.game.round.current_state.name,
        dimentions: state.general.dimensions,
        theme:{...state.theme}
    }
}

const mapDispatchToProps = {  };


export default connect<
StateToPropsType,
DispatchToPropsType,
TimerProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(Timer);
