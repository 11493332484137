import React from 'react';
import { makeStyles, CSSProperties } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        textAlign: 'center', 
        color: 'white',
    },
    img: {
        maxWidth: '100%',
        maxHeight: '100%',
        width: '100%',
    }
}));


export interface SideButtonProps {
    size: number;
    src: any;
    style?: CSSProperties;
}

type Props = SideButtonProps;

const SideButton: React.FC<Props> = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.button} style={{width: props.size, height: props.size, ...props.style}}>
            <img className={classes.img} src={props.src}/>
        </div>
    );
} 

export default SideButton;
