import React, { useEffect } from 'react';
import { ReducersGeneralType } from '../redux/reducers/ReducersGeneral';
import { ReduxStateType } from '../redux/reducers/Reducers';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

export interface RedirectProps {
}

interface StateToPropsType {
    general: ReducersGeneralType;
}

interface DispatchToPropsType {
}

type Props = RedirectProps & StateToPropsType & DispatchToPropsType & RouteComponentProps;

const Redirect: React.FC<Props> = (props) => {

    useEffect(() => {
        const defaultPath = props.location.pathname === '/';
        if (!(defaultPath || props.general.postSetup)) {
            props.history.push('/');
        }
    }, [props.general.postSetup]);

    return (
        null
    );
}

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        general: {...state.general},
    }
}

const mapDispatchToProps = {  };

export default connect<
StateToPropsType,
DispatchToPropsType,
RedirectProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Redirect));
