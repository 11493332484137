import React, {useEffect} from 'react';
import { makeStyles, CSSProperties } from '@material-ui/styles';
import { PlenikaTheme } from '../CustomThemeProvider';
import NumberItem from './NumberItem';
import { connect } from 'react-redux';
import { ReduxStateType } from '../../redux/reducers/Reducers';
import { ReducersGeneralType } from '../../redux/reducers/ReducersGeneral';
import { ReducersGameType } from '../../redux/reducers/ReducersGame';

const useStyles = makeStyles((theme: PlenikaTheme) => {
    return {
        numberBar: {
            width: theme.units.w(100), 
            height: theme.units.h(5.8),
            direction: 'rtl',
            position: 'relative',
            // Side shadow
            '&:after':{
                content: '""',
                position: 'absolute',
                height: '100%',
                top: 0,
                left: theme.units.w(50),
                width: theme.units.w(8),
                background: 'linear-gradient(to right, #000 , #FFFFFF00)'
            }
        },
        containerFrame: {
            width: '50%', 
            overflow: 'hidden', 
            display: 'inline-block',
        }
    };
});


export interface LastNumberBarProps {

}

interface StateToPropsType {
    general: ReducersGeneralType;
    game: ReducersGameType;
}

interface DispatchToPropsType {
}

type Props = LastNumberBarProps & StateToPropsType & DispatchToPropsType;

const LastNumberBar: React.FC<Props> = (props) => {
    const classes = useStyles();
    const perspective = props.general.dimensions ? props.general.dimensions.height : 0; 
    
    useEffect(() => {
        const elem1 = document.getElementById('number_bar_div_1');
        const elem2 = document.getElementById('number_bar_div_2');
        if (elem1) {
            elem1.scrollLeft = perspective*2;
        }
        if (elem2) {
            elem2.scrollLeft = perspective*2;
        }
    });

    return (
        <>
            <div id="number_bar_div_1" className={classes.containerFrame}>
                <div className={classes.numberBar}>
                    {
                        props.game.flatLuckyNumbers.map((result, i)=>(
                            <NumberItem
                                number={result.luckyNumber}
                                key={i}
                                multi={result.multi}
                                lucky
                                first={i===0}
                            />
                        ))
                    }
                </div>
            </div>
            <div id="number_bar_div_2" className={classes.containerFrame}>
                <div className={classes.numberBar}>
                    {
                        props.game.oldResults.map((result, i)=>(
                            <NumberItem
                                number={result.spinResult}
                                key={i}
                            />
                        ))
                    }
                </div>
            </div>
        </>
    );
} 

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        general: {...state.general},
        game: {...state.game},
    }
}

const mapDispatchToProps = { };

export default connect<
StateToPropsType,
DispatchToPropsType,
LastNumberBarProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(LastNumberBar);