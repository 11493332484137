export const videoSettings = {
    url: "http://demo.nanocosmos.de/nanoplayer/api/release/nanoplayer.3.min.js?20190226",
    async: true
}

const videoConfig = function (stream_id: string) {
    return {
        "source": {
            "bintu": {
                "apiurl": "https://bintu.nanocosmos.de",
                "streamid": stream_id
            },
            "entries": [],
            "options": {
                "adaption": {},
                "switch": {}
            },
            "startIndex": 0
        },
        "playback": {
            "autoplay": true,
            "automute": false,
            "muted": false,
            "flashplayer": "//demo.nanocosmos.de/nanoplayer/nano.player.swf"
        },
        "style": {
            "displayMutedAutoplay": false
        }
    }
};
export default videoConfig;
