import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { PlenikaTheme } from '../CustomThemeProvider';
import iconStatistics from '../../assests/icon-statistics.png';
import Tools from '../../tools/Tools';

export interface NumbersBarProps {
    numberItems: NumberItem[];
    lucky?: boolean;
}

type Props = NumbersBarProps;


const useStyles = makeStyles((theme: PlenikaTheme) => {
    
    return {
        root: {
            width: '100%',
            paddingTop: theme.units.h(2),
            fontFamily: `'Alatsi', sans-serif`,
        },
        item: {
            display: 'inline-block',
            width: '7%',
            height: '100%',
            textAlign: 'center',
            color: 'white',
            border: `${theme.units.h(0.2)}px solid #FFFFFF00`,
            borderRadius: `${theme.units.h(0.5)}px`,
            paddingLeft: theme.units.w(0.05),
            paddingRight: theme.units.w(0.05),
            // verticalAlign: 'top',
        },
        img: {
            maxWidth: '100%',
            maxHeight: '100%',
            width: '100%',
            verticalAlign: 'middle',
        },
        colorRed: {
            color: '#c3311c',
        },
        colorGreen: {
            color: '#1ba165',
        },
        colorBlack: {
            color: '#d2cfc5',
        },
        colorLucky: {
            color: '#e1c973',
        },
        bottom: {
            height: theme.units.h(0.5),
        },
        title: {
            fontSize: `${theme.units.h(2)}px`,
            lineHeight: `${theme.units.h(3)}px`,
        },
        subTitle: {
            fontSize: `${theme.units.h(1.2)}px`,
            lineHeight: `${theme.units.h(1.2)}px`,
            color: 'white',
        },
        selectedBottom: {
            backgroundColor: '#d2cfc5!important',
        },
        selectedBorder: {
            borderColor: '#d2cfc5!important',
        },
        luckySelectedBottom: {
            backgroundColor: '#e1c973!important'
        },
        luckySelectedBorder: {
            borderColor: '#e1c973!important'
        },
        
}});

export interface NumberItem {
    number: number;
    subNumber?: number;
}

const NumbersBar: React.FC<Props> = (props) => {
    const classes = useStyles();
    const maxItems = props.lucky ? 12 : 11;

    !props.lucky && console.log('before slice : ', props.numberItems);
    let items: NumberItem[] = props.numberItems;
    if (items.length >= maxItems) {
        items = items.slice(0, maxItems).reverse();
    }
    !props.lucky && console.log('items slice : ', items);

    const selectedBorderClass = props.lucky ?
        classes.luckySelectedBorder: classes.selectedBorder;
    const bottomSelectedClass = props.lucky ? 
        classes.luckySelectedBottom : classes.selectedBottom; 
    return (
        <div className={classes.root}>
            
            { !props.lucky &&
                <div className={classes.item}>
                    <img className={classes.img} src={iconStatistics}/>
                </div> 
            }
            
            {
                items.map( (item, i) => {
                    !props.lucky && console.log(" + " + i, item.number);
                    const selected = i === items.length-1;
                    return (
                        <div key={i} className={[classes.item, classes[Tools.getNumberColor(item.number, props.lucky)], selected?selectedBorderClass:''].join(' ')}>
                            <div className={classes.title}>{item.number}</div>
                            {props.lucky && <div className={classes.subTitle}>x{item.subNumber}</div>}
                            <div className={[selected?bottomSelectedClass:'', classes.bottom].join(' ')}/>
                        </div>
                )})
            }
        </div>
    );
}

export default NumbersBar;