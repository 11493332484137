import React, { useState } from 'react';
import { makeStyles, CSSProperties } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import StatusBar from '../statusbar/StatusBar';
import ProgressBar from '../statusbar/ProgressBar';

/*
interface StateToPropsType {
    general: ReducersGeneralType;
}

interface DispatchToPropsType {
}
*/

export interface CenterColumnProps {
    open: boolean;
    isMobile: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    flexColumn: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    
}));

type Props = CenterColumnProps;

const CenterColumn: React.FC<Props> = (props) => {
    const classes = useStyles();
    let gridBarHeight = props.isMobile ? '33%' : '34%';
    if (props.isMobile && props.open) {
        gridBarHeight = '100%';
    }

    return (
        <div className={classes.flexColumn} style={{
            flex: props.isMobile ? 6 : 5,
        }}>
            <div style={{color: 'white'}}>
                {/* TOP CENTER */}
            </div>
            <div style={{flex: 1}}>
                {/* CENTER */}
            </div>
            <div style={{height: gridBarHeight}}>
                
            </div>
        </div>
    );
}

export default CenterColumn;