import {initiateUserAction, postBetActions, resetBalanceActions} from '../actions/Actions';
import RestService from '../../services/RestService';
import { Dispatch } from 'redux';
import { IUser, IRoundBet,IPostBetsResponse, BetProps, IResponse } from 'plenika-types';
import { ReduxStateType } from '../reducers/Reducers';

type GetState = () => ReduxStateType;

const initiateUser = (request: any) => {
    return async (dispatch: Dispatch, getState: Function) => {
        dispatch(initiateUserAction.start(request));
        const response = await RestService.sendRest('users/adi', null, 'get');
        if (response.success) {
            dispatch(initiateUserAction.success(response));
        } else {
            dispatch(initiateUserAction.error(response));
        }
    }
}

const postBets = (request:any)=>{
    return async (dispatch: Dispatch, getState: GetState) => {
        const user: IUser = getState().game.user;
        
        // Aggregation Start
        const betsMap = {} as {[key: string]: number};
        getState().game.betActions
            .filter( betAction => betAction.active)
            .reduce( (sum, betAction) => [...sum, ...betAction.bets], [] as IRoundBet[] )
            .forEach( roundBet => {
                const sum = betsMap[roundBet.betId] || 0;
                betsMap[roundBet.betId] = roundBet.betAmount + sum;
        });
        const betProps = Object.keys(betsMap)
            .map(betId => ({amount: betsMap[betId], name: betId}) as BetProps);
        // Aggregation End

        console.log('send request ');
        const res: IPostBetsResponse = await RestService.sendRest(
            'round/bets',
            {
                bets: betProps, 
                user: user._id
            },
            'post'
        );
        if (res.success) {
            dispatch(postBetActions.success(res));
        } else {
            dispatch(postBetActions.error(res));
        }
    }
}

const updateUserBalance = (request:any) => {
    return async (dispatch: Dispatch, getState: GetState)=>{
        const user: IUser = getState().game.user
        user.balance = 100000 * 100
        const res: IResponse = await RestService.sendRest('round/resetbalance', user, 'post');
        if (res.success) {
            dispatch(resetBalanceActions.success(res));
        } else {
            dispatch(resetBalanceActions.error(res));
        }
    }
}

export default {
    initiateUser,
    postBets,
    updateUserBalance
}
