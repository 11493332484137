import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import topLeftButtonsImg from '../../assests/top_right_image.png';
import { PlenikaTheme } from '../CustomThemeProvider';
import { connect } from 'react-redux';
import { ReduxStateType } from '../../redux/reducers/Reducers';
import { ReducersGameType } from '../../redux/reducers/ReducersGame';
import RoundDetails from '../RoundDetails';

export interface RightColumnProps {
    open: boolean;
    isMobile: boolean;
}

interface StateToPropsType {
    game: ReducersGameType;
}

interface DispatchToPropsType {
}

type Props = RightColumnProps & StateToPropsType & DispatchToPropsType;

const useStyles = makeStyles((theme: PlenikaTheme) => {
    
    return {
        flexColumn: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        topTextFrame: {
            textAlign: 'right',
            fontSize: `${theme.units.h(theme.general.isMobile?2.7:1.2)}px`,
            paddingRight: theme.units.h(3),
            paddingTop: theme.units.h(1),
            paddingBottom: theme.units.h(1),
            color: '#555555',
        },
        topButtonsFrame: {
            textAlign: 'right',
            display: theme.general.isMobile ? 'none' : 'block',
            // Just for the image
            paddingRight: theme.units.h(2.5),
            height: theme.units.h(3),
        },
        buttonsImg: {
            maxHeight: '100%',
            maxWidth: '100%',
        },
        topFrame: {
            // TODO move the padding for comp above
            paddingTop: theme.units.h(theme.general.isMobile?(5)+2/*5 is for bar height */:0),
            zIndex: 1000,
        },
    };
});

const RightColumn: React.FC<Props> = (props) => {
    const classes = useStyles();

    const [dummyCounter, setDummyCounter] = useState<number>(1);
    useEffect(() => {
        setDummyCounter(dummyCounter+1);
    }, [props.game.round._id])
    
    return (
        <div className={classes.flexColumn} style={{
            flex: 3,
            display: (props.open && props.isMobile) ? 'none' : undefined,
        }}>
            <div className={classes.topFrame}>
                <div className={classes.topTextFrame}>
                    <RoundDetails 
                        roundId={'#BBR000'+dummyCounter}
                        startTime={props.game.loginTime}
                    />
                </div>
                <div className={classes.topButtonsFrame}>
                    <img className={classes.buttonsImg} src={topLeftButtonsImg}/>
                </div>
            </div>
            <div style={{flex: 1}}>
                [CHAT-NOT-INCLUDING-PADDING]
            </div>
            <div style={{height: '25%'}}>
                [BOTTOM-LEFT-UI]
            </div>
        </div>
    );
} 

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        game: {...state.game}
    }
}

const mapDispatchToProps = { 

};

export default connect<
StateToPropsType,
DispatchToPropsType,
RightColumnProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(RightColumn);