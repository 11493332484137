import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { ReducersGameType, BetAction } from '../../redux/reducers/ReducersGame';
import { ReducersGeneralType } from '../../redux/reducers/ReducersGeneral';
import { ReduxStateType } from '../../redux/reducers/Reducers';
import { connect } from 'react-redux';
import SyncActions from '../../redux/actions/SyncActions';
import doubleIcon from '../../assests/double-3.png';
import rebetIcon from '../../assests/rebet-3.png';
import { BetTags } from '../../tools/Keys';
import GridButton from './GridButton';
import Tools from '../../tools/Tools';
import { RoundBet, getTotalBets } from 'plenika-types';

export interface DoubleButtonProps {
};


interface StateToPropsType {
    general: ReducersGeneralType;
    game: ReducersGameType;
}

interface DispatchToPropsType {
    reBet: typeof SyncActions.reBet;
    doubleBet: typeof SyncActions.doubleBet;
    addBets: typeof SyncActions.addBets;
}

type Props = DoubleButtonProps & StateToPropsType & DispatchToPropsType;
  
const DoubleButton: React.FC<Props> = (props) => {
    // Start Get Vars Ready
    const lastActionsEmpty =    props.game.lastBet.filter(a=>a.active).length    === 0;
    const currentActionsEmpty = props.game.betActions.filter(a=>a.active).length === 0;
    const doubleMod = !currentActionsEmpty;

    const actionsToAdd = doubleMod ? props.game.betActions : props.game.lastBet;
    const betsToAdd = Tools.reduceBetActions(actionsToAdd);
    // TODO possibly remove the use of 'addBetsValidReports' for a creative idea
    const reports = Tools.addBetsValidReports(
        props.game.betActions,
        betsToAdd,
        props.game.user.balance 
    );
    const disable = (getTotalBets(actionsToAdd) > props.game.user.balance) || (!Tools.isReportsCanAdd(reports));
    // End Get Vars Ready

    const handleClick = () => {
        if (disable) {
            return;
        }
        props.addBets(
            betsToAdd,  
            doubleMod ? BetTags.DOUBLE_BET : BetTags.RE_BET
        );
    };
    return (
        <GridButton
            disable={disable}
            onClick={handleClick}
            text={doubleMod ? 'Double' : 'Rebet'}
            leftIcon={doubleMod ? doubleIcon : rebetIcon}
        />
    );
}

/**
 * only if apear on active action
 * @param betActions 
 */
const isTagAlreadyApear = (betActions: BetAction[], tag: string) => {
    return !!betActions.filter(b => b.active).find(b => b.tag === tag);
};

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        general: {...state.general},
        game: {...state.game},
    }
}

const mapDispatchToProps = { 
    reBet: SyncActions.reBet, 
    doubleBet: SyncActions.doubleBet,
    addBets: SyncActions.addBets, 
};

export default connect<
StateToPropsType,
DispatchToPropsType,
DoubleButtonProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(DoubleButton);
