import React from 'react';
import { makeStyles, CSSProperties } from '@material-ui/styles';
import { PlenikaTheme } from '../CustomThemeProvider';
import Tools from '../../tools/Tools';
import {ReactComponent as Star} from '../../assests/star.svg';

const useStyles = makeStyles((theme: PlenikaTheme) => {
    return {
        itemFrame: {
            position: 'relative',
            display: 'inline-block',
            width: theme.units.h(5.8),
            height: '100%',
            textAlign: 'center',
            backgroundColor: '#0d0100',
            color: 'white',
            verticalAlign: 'top',
            direction: 'ltr',
    
            lineHeight: `${theme.units.h(5.8)}px`,
            fontSize:   `${theme.units.h(5.8)*0.8}px`,
            fontFamily: `'Alatsi', sans-serif`,
        },
        itemFrameLucky: {
            width: `${theme.units.h(13)}px!important`,
            fontSize: `${theme.units.h(5.8)*0.7}px!important`,
        },
        colorRed: {
            color: '#c3311c',
        },
        colorGreen: {
            color: '#1ba165',
        },
        colorBlack: {
            color: '#d2cfc5',
        },
        colorLucky: {
            color: '#e1c973',
        },
        subTitle: {
            fontSize: `${theme.units.h(5.8)*0.5}px`,
            lineHeight: `${theme.units.h(5.8)}px`,
            display: 'inline',
        },
        selectedBottom: {
            backgroundColor: '#d2cfc5!important',
        },
        selectedBorder: {
            borderColor: '#d2cfc5!important',
        },
        luckySelectedBottom: {
            backgroundColor: '#e1c973!important',
        },
        luckySelectedBorder: {
            borderColor: '#e1c973!important'
        },
        first: {
            backgroundColor: '#ffe69c!important',
            color: '#000!important',
        },
        star1: {
            zIndex: 2000,
            position: 'absolute', 
            width:  theme.units.h(5),
            height: theme.units.h(5),
            top:   -theme.units.h(2.5), 
            right: -theme.units.h(2.5),
        },
        star3: {
            zIndex: 2000,
            position: 'absolute', 
            width:  theme.units.h(2),
            height: theme.units.h(2),
            bottom: 0, 
            right:  theme.units.h(1),
        },
    };
});


export interface NumberItemProps {
    number: number;
    lucky?: boolean;
    multi?: number;
    first?: boolean;
}

type Props = NumberItemProps;

const NumberItem: React.FC<Props> = (props) => {
    const classes = useStyles();
    const color = Tools.getNumberColor(props.number, props.lucky);

    const stars = (
        <>
            <Star  className={classes.star1}/>
            <Star  className={classes.star3}/>
        </>
    );

    return (
        <div className={[
                classes.itemFrame, 
                classes[color], 
                props.lucky ? classes.itemFrameLucky : '',
                props.first ? classes.first : '', 
            ].join(' ')}>
            {props.number} {props.lucky && <div className={classes.subTitle}>x{props.multi}</div>}
            {props.first && stars}
        </div>
    );
} 

export default NumberItem;
