import { makeStyles } from "@material-ui/styles";
import React from 'react';
import SyncActions from "../../redux/actions/SyncActions";
import { connect } from "react-redux";
import { ReduxStateType } from "../../redux/reducers/Reducers";
import { Dimensions } from "../../redux/reducers/ReducersGeneral";
import Chip from "./Chip";
import { PlenikaTheme } from "../CustomThemeProvider";
import Tools from "../../tools/Tools";

export interface DesktopBettingToolProps {

}

interface StateToPropsType {
    dimensions?: Dimensions,
    selectedChip: number,
}

interface DispatchToPropsType {
    setSelectedChip: typeof SyncActions.setSelectedChip;
}

type Props = DesktopBettingToolProps & StateToPropsType & DispatchToPropsType;

const CHIP_SIZE = 4.6;
const CHIP_PAD = 0.23;

const useStyles = makeStyles((theme: PlenikaTheme) => ({
    chip: {
        width: theme.units.h(CHIP_SIZE),
        height: theme.units.h(CHIP_SIZE),
        display: 'inline-block',
        paddingRight: theme.units.w(CHIP_PAD),
        paddingLeft: theme.units.w(CHIP_PAD),
        position: 'relative',
        transitionDuration: '0.7s',
        '&:hover': {
            paddingBottom: theme.units.h(2),
        },
    },
    chipDown: {
        '-webkit-mask-image': 'linear-gradient(to bottom, rgba(0,0,0,1) 20%, transparent 80%)',
        //top: theme.units.h(1.0),
    },
    chipUp: {
        paddingBottom: theme.units.h(2),
    },
    bottomLine: {
        height: `${theme.units.h(0.5)}px`,
        width:  `${theme.units.h(CHIP_SIZE)}px`,
        // paddingRight: theme.units.h(CHIP_PAD),
        // paddingLeft:  theme.units.h(CHIP_PAD),
        backgroundColor: "#FFFFFF",
        position: 'absolute',
        bottom: theme.units.h(0),
    },
    flexContainer: {
        display: 'inline-flex',
        alignItems: 'flex-end',
        lineHeight: 0,
        height: '100%',
        justifyContent: 'flex-end',
        verticalAlign: 'bottom',
        paddingLeft: theme.units.w(1),
        paddingRight: theme.units.w(1),
    },
}));

const DesktopBettingTool: React.FC<Props> = (props) => {
    const classes = useStyles();

    const createHandleChipClick = (amount: number) => {
        return () => props.setSelectedChip(amount);
    };

    return (
        <>
            <div className={classes.flexContainer}>
                {
                    Tools.getChipNumbers().map((amount, i) => {
                        const selected = amount === props.selectedChip;
                        return (
                            <div key={i} onClick={createHandleChipClick(amount)} className={[
                                    classes.chip, 
                                    (selected ? classes.chipUp : classes.chipDown),
                                ].join(' ')}
                            >
                                <Chip amount={amount} />
                                <div className={classes.bottomLine}/>
                            </div>
                        )   
                    })
                }
            </div>
        </>
    )
};

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        dimensions: state.general.dimensions ? {...state.general.dimensions} : undefined,
        selectedChip: state.game.selectedChip,
    }
}

const mapDispatchToProps = { setSelectedChip: SyncActions.setSelectedChip };

export default connect<
StateToPropsType,
DispatchToPropsType,
DesktopBettingToolProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(DesktopBettingTool);