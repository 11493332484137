import React from 'react';
import { makeStyles } from '@material-ui/styles';
import WinnersList from '../WinnersList';
import {ReactComponent as IconV} from '../../assests/icon-v.svg';
import { PlenikaTheme } from '../CustomThemeProvider';
import { connect } from 'react-redux';
import { ReduxStateType } from '../../redux/reducers/Reducers';
import SyncActions from '../../redux/actions/SyncActions';
import { ReducersGameType } from '../../redux/reducers/ReducersGame';
import { PopupKeys } from '../../tools/Keys';
import { GameStateKeys } from 'plenika-types';
import LuckyNumbersRoulette from '../lastnumbers/LuckyNumbersRoulette';

export interface LeftColumnProps {
    open: boolean;
    isMobile: boolean;
}

interface StateToPropsType {
    game: ReducersGameType;
}

interface DispatchToPropsType {
    setPopupFlag: typeof SyncActions.setPopupFlag,
}

const useStyles = makeStyles((theme: PlenikaTheme) => ({
    flexColumn: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    iconV: {
        width: theme.units.h(2.2),
        height: theme.units.h(2.2),
        paddingLeft: theme.units.h(0.5),
        paddingRight: theme.units.h(0.5),
        transition: '0.4s',
        ...(theme.general.isMobile ? {
            display: 'none',
        } : {}),
    },
    spinedIcon: {
        transform: `rotate(180deg)`,
    },
    topFrame: {
        fontSize: `${theme.units.h(theme.general.isMobile?2.8:2)}px`,
        marginLeft: theme.units.w(1.2),
        color: 'white',
        marginTop: theme.units.h(theme.general.isMobile?(5)+2/*5 for top bar */:0),
        zIndex: 3000,
        ...(theme.general.isMobile ? {
            paddingTop: theme.units.h(1),
            paddingBottom: theme.units.h(1),
        } : {}),
    },
    winningNumbers: {
        display: 'inline', 
        color: '#fff3c2',
        fontWeight: 1000,
    },
    inlineBlock: {
        display: 'inline-block',
    },
    absuloteContent: {
        top: 0, 
        left: 0, 
        position: 'absolute', 
        width: '100%', 
        height: '100%',
    }
}));

type Props = LeftColumnProps & StateToPropsType & DispatchToPropsType;

const LeftColumn: React.FC<Props> = (props) => {
    const classes = useStyles();
    
    let showLuckyRoulette = [
        GameStateKeys.RESULTS, 
        GameStateKeys.GAME_ROUND, 
        GameStateKeys.GAME_ROUND_2
    ].includes(props.game.round.current_state.name);
    // showLuckyRoulette = false;
    console.log('showLuckyRoulette: ', showLuckyRoulette);
    console.log(' 00 0 0', props.game.round.result);
    const centerLeft = props.isMobile ? (
        showLuckyRoulette ? <LuckyNumbersRoulette/> : (<div style={{width: '100%', height: '100%', color: 'white'}}>
            [TODO CENTER]
        </div>)
    ) : (
        <>
            <div className={classes.absuloteContent} style={{
                visibility: showLuckyRoulette ? 'visible' : 'hidden'
            }}>
                {props.game.round.result && 
                    props.game.round.result.luckyNumbers && 
                    <LuckyNumbersRoulette/>}
            </div>
            <div className={classes.absuloteContent} style={{
                visibility: showLuckyRoulette ? 'hidden' : 'visible',
            }}>
                <WinnersList/>
            </div>
        </>
    );

    const togglePopup = () => props.setPopupFlag(PopupKeys.TABLE, !props.game.popupsState[PopupKeys.TABLE]);
    const openPopup =   () => props.setPopupFlag(PopupKeys.TABLE, true);

    return (
        <div className={classes.flexColumn} style={{
            flex: 3,
            display: (props.open && props.isMobile) ? 'none' : undefined, 
        }}>
            <div className={classes.topFrame}>
                <div className={classes.inlineBlock} onClick={togglePopup} onMouseOver={openPopup}>
                    Big Band Roulette <div className={classes.winningNumbers}>$0.20 - 1,000</div>
                    <div style={{display: 'inline'}}><IconV className={[classes.iconV, props.game.popupsState[PopupKeys.TABLE] ? classes.spinedIcon : ''].join(' ')}/></div>
                </div>
            </div>
            <div style={{flex: 1, overflow: 'hidden', position: 'relative'}}>
                {centerLeft}
            </div>
            <div style={{height: '25%'}}>
                
            </div>
        </div>
    );
} 

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        game: {...state.game},
    }
}

const mapDispatchToProps = { 
    setPopupFlag: SyncActions.setPopupFlag,
};

export default connect<
StateToPropsType,
DispatchToPropsType,
LeftColumnProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(LeftColumn);