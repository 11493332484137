import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { PlenikaTheme } from '../CustomThemeProvider';
import { ReduxStateType } from '../../redux/reducers/Reducers';
import { connect } from 'react-redux';
import { ReducersGameType } from '../../redux/reducers/ReducersGame';
import { redNumbers, blackNumbers } from 'plenika-types/dist/modules/bet/Bet';
import iconStatistics from '../assests/icon-statistics.png';
import NumbersBar from './NumbersBar';

export interface LastNumbersProps {
}

interface StateToPropsType {
    game: ReducersGameType;
}

interface DispatchToPropsType {
}


const useStyles = makeStyles((theme: PlenikaTheme) => {
    
});

type Props = LastNumbersProps & StateToPropsType & DispatchToPropsType;

export interface BarItem {
    number: number;
    subNumber?: number;
}

const LastNumbers: React.FC<Props> = (props) => {
    const classes = useStyles();
    console.log('set THOUSE : ');
    props.game.oldResults.forEach((res, i) => {
        console.log('here : ' + i , res.spinResult);
    });
    return (
        <NumbersBar
            numberItems={props.game.oldResults.map(res=>{console.log(' - ', res.spinResult); return ({number: res.spinResult})})}
        />
    );
}

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        game: {...state.game},
    }
}

const mapDispatchToProps = { 
};

export default connect<
StateToPropsType,
DispatchToPropsType,
LastNumbersProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(LastNumbers);