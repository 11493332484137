import React, { useEffect } from 'react';
import { ReducersGeneralType } from '../redux/reducers/ReducersGeneral';
import { ReducersGameType } from '../redux/reducers/ReducersGame';
import { ReduxStateType } from '../redux/reducers/Reducers';
import { connect } from 'react-redux';
import SyncActions from '../redux/actions/SyncActions';
import AsyncActions from '../redux/actions/AsyncActions';
import SocketService from '../services/SocketService';

export interface SocketServiceCompProps {
};


interface StateToPropsType {
    general:            ReducersGeneralType;
    game:               ReducersGameType;
}

interface DispatchToPropsType {
    setLuckyNumbers:    typeof SyncActions.setLuckyNumbers;
    setRound:           typeof SyncActions.setRound;
    setRoundStatus:     typeof SyncActions.setRoundStatus;
    postBets:           Function;
    clearBets:          typeof SyncActions.clearBets;
    setResult:          typeof SyncActions.setResult;
    setPopupLoginFlag:  typeof SyncActions.setPopupLoginFlag;
    setPopupFlag:       typeof SyncActions.setPopupFlag;
    setUser:            typeof SyncActions.setUser;
    setLoginTime:       typeof SyncActions.setLoginTime;
    setToken:           typeof SyncActions.setToken;
    setPopupMessage:    typeof SyncActions.setPopupMessage;
    setOldResults:      typeof SyncActions.setOldResults;
    setNanoStreamToken: typeof SyncActions.setNanoStreamToken;
}

type Props = SocketServiceCompProps & StateToPropsType & DispatchToPropsType;

export interface SocketProps extends Props {};

const SocketServiceComp: React.FC<Props> = (props) => {    
    SocketService.setProps(props);
    useEffect(() => {
        SocketService.startSesstion();
    }, []);
    return (
        <></>
    );
}

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        general: {...state.general},
        game:    {...state.game},
    }
}

const mapDispatchToProps = { 
    setLuckyNumbers:    SyncActions.setLuckyNumbers,
    setRound:           SyncActions.setRound,
    setRoundStatus:     SyncActions.setRoundStatus,
    postBets:           AsyncActions.postBets,
    clearBets:          SyncActions.clearBets,
    setResult:          SyncActions.setResult,
    setPopupLoginFlag:  SyncActions.setPopupLoginFlag,
    setPopupFlag:       SyncActions.setPopupFlag,
    setUser:            SyncActions.setUser,
    setLoginTime:       SyncActions.setLoginTime,
    setToken:           SyncActions.setToken,
    setNanoStreamToken: SyncActions.setNanoStreamToken,
    setPopupMessage:    SyncActions.setPopupMessage,
    setOldResults:      SyncActions.setOldResults,
};

export default connect<
StateToPropsType,
DispatchToPropsType,
SocketServiceCompProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(SocketServiceComp);
