import {combineReducers} from "redux";
import ReducersGeneral, {ReducersGeneralType} from "./ReducersGeneral";
import ReducersTheme, {ReducersThemeType} from "./ReducersTheme";
import ReducersGame, { ReducersGameType } from "./ReducersGame";


export interface ReduxStateType {
    general: ReducersGeneralType,
    theme: ReducersThemeType,
    game: ReducersGameType,
}

export default combineReducers({
    general: ReducersGeneral,
    theme: ReducersTheme,
    game: ReducersGame,
});
