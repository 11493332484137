import React from 'react';
import Tools from '../../tools/Tools';
import { makeStyles } from '@material-ui/styles';
import { PlenikaTheme } from '../CustomThemeProvider';

const useStyles = makeStyles((theme: PlenikaTheme) => {
    return {
        container: {
            textAlign: 'center',
            display: 'inline-block',
            whiteSpace: 'nowrap',
            height: theme.units.h(4),
        },
        textFrame: {
            display: 'inline-block',
            fontSize: `${theme.units.h(2)}px`,
            color: 'white',
            verticalAlign: 'middle',
        },
        iconFrame: {
            display: 'inline-block',
            width: theme.units.h(4),
            height: theme.units.h(4),
            verticalAlign: 'middle',
        },
        icon: {
            maxWidth: '100%',
            maxHeight: '100%',
            width: '100%',
        }
    };
});

export interface VerticalButtonProps {
    text: string;
    icon: any;
}

type Props = VerticalButtonProps;

const VerticalButton: React.FC<Props> = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.iconFrame}>
                <img className={classes.icon} src={props.icon}/>
            </div>
            <div className={classes.textFrame}>
                {props.text}
            </div>
        </div>
    );
} 

export default VerticalButton;
