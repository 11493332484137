import React, { DOMAttributes, ClassAttributes, SVGAttributes, useEffect } from 'react';
import Tools from '../../tools/Tools';
import { makeStyles } from '@material-ui/styles';
import { PlenikaTheme } from '../CustomThemeProvider';
import { ReactComponent as MobileBettingGridSvg } from '../../assests/Grid_Mobile.svg';
import useGradient, { generateGradient } from '../hooks/UseGradient';

const useStyles = makeStyles((theme: PlenikaTheme) => {
    const gradient = generateGradient(['stroke'], 'borderAnim');
    return {
        ...gradient,
    };
});

export interface MobileBettingGridProps extends 
    DOMAttributes<SVGSVGElement>, 
    ClassAttributes<SVGSVGElement>, 
    SVGAttributes<SVGSVGElement> {
}

type Props = MobileBettingGridProps;

const MobileBettingGrid = React.forwardRef<any, Props>((props, ref) => {
    const classes = useStyles();
    const additinalClasses = props.className ? props.className : '';
    const gradientValues = useGradient();

    useEffect(() => {
        // @ts-ignore
        const borders = document.getElementById('Borders') as SVGGElement;
        for (let i=0; i<borders.childNodes.length; i++) {
            const element = (borders.childNodes[i] as HTMLElement);
            element.setAttribute('style', `
                animation: ${gradientValues.style.animation};
                animation-delay: ${gradientValues.style.animationDelay};
            `);
            
            if (gradientValues.canBet) {
                element.classList.add(classes.gradientAnim);
            } else {
                element.classList.remove(classes.gradientAnim);
            }
        }
    });
    return (
        <MobileBettingGridSvg 
            {...props} 
            className={classes.gradientAnim+' '+additinalClasses}
            ref={ref}
        />
    );
}); 

export default MobileBettingGrid;