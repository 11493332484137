"use strict";

var _classCallCheck = require("/opt/plenika/plenika_new/plenika-app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var Response = function Response(props) {
  _classCallCheck(this, Response);

  this.success = props.success;
  this.message = props.message || "Unexpected error";
  this.status = props.status || null;
};

exports.default = Response;