import { ActionKeys, ActionStatusKeys } from "../../tools/Keys";
import { IResponse, Bet } from 'plenika-types';
import { request } from "http";

export const initiateUserAction = Object.freeze({
    start: (request: any) => {
        return {
            type: ActionKeys.INTIATE_USER,
            status: ActionStatusKeys.START,
            request: request,
        }
    },
    success: (response: IResponse) => {
        return {
            type: ActionKeys.INTIATE_USER,
            status: ActionStatusKeys.SUCCESS,
            response: response,
        }
    },
    error: (response: IResponse) => {
        return {
            type: ActionKeys.INTIATE_USER,
            status: ActionStatusKeys.FAIL,
            response: response,
        }
    }
});

export const postBetActions = Object.freeze({
    start: (request: any) => {
        return {
            type: ActionKeys.POST_BETS,
            status: ActionStatusKeys.START,
            request: request,
        }
    },
    success: (response: IResponse) => {
        return {
            type: ActionKeys.POST_BETS,
            status: ActionStatusKeys.SUCCESS,
            response: response,
        }
    },
    error: (response: IResponse) => {
        return {
            type: ActionKeys.POST_BETS,
            status: ActionStatusKeys.FAIL,
            response: response,
        }
    }
});

export const resetBalanceActions = Object.freeze({
    start: (request: any) => {
        return {
            type: ActionKeys.POST_BALANCE_RESET,
            status: ActionStatusKeys.START,
            request: request,
        }
    },
    success: (response: IResponse) => {
        return {
            type: ActionKeys.POST_BALANCE_RESET,
            status: ActionStatusKeys.SUCCESS,
            response: response,
        }
    },
    error: (response: IResponse) => {
        return {
            type: ActionKeys.POST_BALANCE_RESET,
            status: ActionStatusKeys.FAIL,
            response: response,
        }
    }
});

