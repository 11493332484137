
export interface HexType {
    r:number;
    g:number;
    b:number;
    hex?:string;
}


export  class Color implements HexType{
    r:number;
    g:number;
    b:number;
    constructor(params:HexType) {
        let obj:HexType;
        if (!params.hex) {
            obj = params;
        } else {
            obj = LinearColorInterpolator.convertHexToRgb(params.hex);
        }
        this.r = obj.r;
        this.g = obj.g;
        this.b = obj.b;
    }

    asRgbCss() {
        return "rgb("+this.r+", "+this.g+", "+this.b+")";
    }
}


const LinearColorInterpolator = {
    convertHexToRgb: function(hex:string) {
        const match:any = hex.replace(/#/,'').match(/.{1,2}/g);
        return new Color({
            r: parseInt(match[0], 16),
            g: parseInt(match[1], 16),
            b: parseInt(match[2], 16)
        });
    },
    findColorBetween: function(left:HexType, right:HexType, percentage:number) {
        let newColor:HexType = {
            r:Math.round(left['r'] + (right['r'] - left['r']) * percentage / 100),
            g:Math.round(left['g'] + (right['g'] - left['g']) * percentage / 100),
            b:Math.round(left['b'] + (right['b'] - left['b']) * percentage / 100),
        };
        return new Color(newColor);
    }
}

export default LinearColorInterpolator;


// var l = new Color({ r:100, g:100, b:100 });
// var r = new Color("#ffaa33");
// var backgroundColor = LinearColorInterpolator.findColorBetween(l, r, 50).asRgbCss();