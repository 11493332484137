
export interface ReducersThemeType {
    
}

function getInitialState(): ReducersThemeType {
    return {
    };
}

export default function (state = getInitialState(), action: any): ReducersThemeType {
    switch (action.type) {
        
    }

    return state;
}