import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { ReducersGeneralType } from '../../redux/reducers/ReducersGeneral';
import { ReducersGameType } from '../../redux/reducers/ReducersGame';
import { ReduxStateType } from '../../redux/reducers/Reducers';
import { connect } from 'react-redux';
import { PlenikaTheme } from '../CustomThemeProvider';
import Tools from '../../tools/Tools';
import useGradient, { generateGradient, Colors } from '../hooks/UseGradient';

export interface ProgressBarProps {
}

interface StateToPropsType {
    
}

interface DispatchToPropsType {
}

type Props = ProgressBarProps & StateToPropsType & DispatchToPropsType;

const useStyles = makeStyles((theme: PlenikaTheme) => {
    const grediant = generateGradient(['stopColor'], 'progressBarAnim');
    return {
        progressBar:{
            width: '100%',
            height: '100%',
            transition:'18s linear',
        },
        progressBarWraped: {
            width: '0%!important',
        },
        rect: {
            fill: 'url(#gridProgressGradient)',
        },
        ...grediant,
        frame: {
            width: '100%', 
            position: 'absolute',
        },
        '@keyframes barWidthKeyframe': {
            '0%': {
                transform: 'scale(1,1)'
            },
            '100%': {
                transform: 'scale(0,1)'
            },
        },
        barWidthAnim: {
            animationName: '$barWidthKeyframe!important',
        }
    };
});

const WIDTH = 1000;
const HEIGHT = 10;

const ProgressBar: React.FC<Props> = (props) => {
    const classes = useStyles();
    const gradientValues = useGradient();
    return (
        <div className={classes.frame}>
            <div className={gradientValues.canBet?classes.barWidthAnim:''} 
            style={gradientValues.style}>
                <svg
                    style={{width: '100%', display: 'block', height: '100%'}}
                    viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
                    version="1.1"
                    id="gridBorder"
                >
                    <defs id="gridProgressDefs">
                        <linearGradient 
                            id="gridProgressGradient" 
                            gradientTransform="rotate(0)" 
                            gradientUnits="userSpaceOnUse">
                            <stop 
                                offset="0" 
                                stopColor="#ffffff00"
                            />
                            <stop 
                                offset="0.5" 
                                stopColor="#ffffff00" 
                                className={gradientValues.canBet ? classes.gradientAnim : ''}
                                style={gradientValues.style}
                            />
                            <stop 
                                offset="1" 
                                stopColor="#ffffff00"
                            />
                        </linearGradient>
                    </defs>
                    <g id="geidBorderLayer">
                        <rect
                            id="rect"
                            style={{width: '100%', height: '100%'}}
                            className={[classes.rect].join(' ')}
                        />
                        
                    </g>
                </svg>
            </div>
        </div>
    );
}

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        
    }
}

const mapDispatchToProps = { };

export default connect<
StateToPropsType,
DispatchToPropsType,
ProgressBarProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(ProgressBar);
