import { IWinner, IUser } from "plenika-types";

const winners : IWinner[] = [
    {
        amount: 790913480,
        user: ({
            username: 'bardell3',
        } as IUser)
    },
    
    {
        amount: 762105800,
        user: ({
            username: 'fibbitson',
        } as IUser)
    },
    
    {
        amount: 216115260,
        user: ({
            username: 'tiffey1982',
        } as IUser)
    },
    
    {
        amount: 554105620,
        user: ({
            username: 'parent15',
        } as IUser)
    },
    
    {
        amount: 629521990,
        user: ({
            username: 'wolf',
        } as IUser)
    },
    
    {
        amount: 179169840,
        user: ({
            username: 'mowcher',
        } as IUser)
    },
    
    {
        amount: 717990130,
        user: ({
            username: 'harold55',
        } as IUser)
    },
    
    {
        amount: 674763010,
        user: ({
            username: 'kenwigs',
        } as IUser)
    },
    
    {
        amount: 170968410,
        user: ({
            username: 'rigaud',
        } as IUser)
    },
    
    {
        amount: 501636730,
        user: ({
            username: 'podsnap',
        } as IUser)
    },
    
    {
        amount: 412837150,
        user: ({
            username: 'monurel',
        } as IUser)
    },
    
    {
        amount: 197156100,
        user: ({
            username: 'margaret',
        } as IUser)
    },
    
    {
        amount: 214727480,
        user: ({
            username: 'Eli3000',
        } as IUser)
    },
    
    {
        amount: 702944380,
        user: ({
            username: 'richard3',
        } as IUser)
    },
    
    {
        amount: 559046740,
        user: ({
            username: 'Adi_I',
        } as IUser)
    },
    
    {
        amount: 281057710,
        user: ({
            username: '123quilp',
        } as IUser)
    },
    
    {
        amount: 365198960,
        user: ({
            username: 'georgiana',
        } as IUser)
    },
    
    {
        amount: 369587580,
        user: ({
            username: 'maggy',
        } as IUser)
    },
    
    {
        amount: 41645060,
        user: ({
            username: 'blackboy66',
        } as IUser)
    },
    
    {
        amount: 56686330,
        user: ({
            username: 'coiler',
        } as IUser)
    },
    
    {
        amount: 21713550,
        user: ({
            username: 'bulph',
        } as IUser)
    },
    
    {
        amount: 152310,
        user: ({
            username: 'malta_3',
        } as IUser)
    },
    
    {
        amount: 455900,
        user: ({
            username: 'E1tobias',
        } as IUser)
    },
    
    {
        amount: 633850,
        user: ({
            username: 'nelly',
        } as IUser)
    },
    
    {
        amount: 228730,
        user: ({
            username: 'gordon12',
        } as IUser)
    },
    
    {
        amount: 551970,
        user: ({
            username: 'jorkins',
        } as IUser)
    },
    
    {
        amount: 191040,
        user: ({
            username: 'godfrey',
        } as IUser)
    },
    
    {
        amount: 632480,
        user: ({
            username: 'bantam',
        } as IUser)
    },
    
    {
        amount: 874360,
        user: ({
            username: 'becky',
        } as IUser)
    },
    
    {
        amount: 834940,
        user: ({
            username: 'rose_n',
        } as IUser)
    },
    
    {
        amount: 186190,
        user: ({
            username: 'stagg',
        } as IUser)
    },
    
    {
        amount: 522540,
        user: ({
            username: 'slackbridge',
        } as IUser)
    },
    
    {
        amount: 27530,
        user: ({
            username: 'darby67',
        } as IUser)
    },
    
    {
        amount: 362360,
        user: ({
            username: 'fang',
        } as IUser)
    },
    
    {
        amount: 270870,
        user: ({
            username: 'badger',
        } as IUser)
    },
    
    {
        amount: 858310,
        user: ({
            username: 'PGvenus',
        } as IUser)
    },
    
    {
        amount: 359090,
        user: ({
            username: 'barnacles',
        } as IUser)
    },
    
    {
        amount: 65278,
        user: ({
            username: 'brownlow',
        } as IUser)
    },
    
    {
        amount: 7792,
        user: ({
            username: 'arabella',
        } as IUser)
    },
    
    {
        amount: 80494,
        user: ({
            username: 'bailey1978',
        } as IUser)
    },
    
    {
        amount: 52397,
        user: ({
            username: 'bedwin',
        } as IUser)
    },
    
    {
        amount: 9565,
        user: ({
            username: 'pross',
        } as IUser)
    },
    
    {
        amount: 86489,
        user: ({
            username: 'scaley',
        } as IUser)
    },
    
    {
        amount: 62607,
        user: ({
            username: 'slammer_f',
        } as IUser)
    },
    
    {
        amount: 89028,
        user: ({
            username: 'jemmy',
        } as IUser)
    },
    
    {
        amount: 71264,
        user: ({
            username: 'dot',
        } as IUser)
    },
    
    {
        amount: 64258,
        user: ({
            username: 'clara',
        } as IUser)
    },
    
    {
        amount: 33815,
        user: ({
            username: 'solomon',
        } as IUser)
    },
    
    {
        amount: 88679,
        user: ({
            username: 'heep',
        } as IUser)
    },
    
    {
        amount: 13858,
        user: ({
            username: 'marwood',
        } as IUser)
    },
    
    {
        amount: 69904,
        user: ({
            username: 'graymarsh',
        } as IUser)
    },
    
    {
        amount: 50765,
        user: ({
            username: 'jooooo',
        } as IUser)
    },
    
    {
        amount: 61353,
        user: ({
            username: 'herbert',
        } as IUser)
    },
    
    {
        amount: 62036,
        user: ({
            username: 'estella',
        } as IUser)
    },
    
    {
        amount: 57654,
        user: ({
            username: 'chowser',
        } as IUser)
    },
    
    {
        amount: 89404,
        user: ({
            username: 'billickin',
        } as IUser)
    },
    
    {
        amount: 80900,
        user: ({
            username: 'ba45rt',
        } as IUser)
    },
    
    {
        amount: 67005,
        user: ({
            username: 'melvilleson',
        } as IUser)
    },
    
    {
        amount: 83682,
        user: ({
            username: 'flora',
        } as IUser)
    },
    
    {
        amount: 77664,
        user: ({
            username: 'hannah',
        } as IUser)
    },
    
    {
        amount: 14786,
        user: ({
            username: 'gregory1',
        } as IUser)
    },
    
    {
        amount: 98019,
        user: ({
            username: 'mealy',
        } as IUser)
    },
    
    {
        amount: 2376,
        user: ({
            username: 'madame',
        } as IUser)
    },
    
    {
        amount: 24428,
        user: ({
            username: 'infant_18',
        } as IUser)
    },
    
    {
        amount: 94138,
        user: ({
            username: 'weller',
        } as IUser)
    },
    
    {
        amount: 1638,
        user: ({
            username: 'peerybingle',
        } as IUser)
    },
    
    {
        amount: 44682,
        user: ({
            username: 'scott',
        } as IUser)
    },
    
    {
        amount: 51773,
        user: ({
            username: 'black',
        } as IUser)
    },
    
    {
        amount: 25495,
        user: ({
            username: 'tracy_m',
        } as IUser)
    },
    
    {
        amount: 9007,
        user: ({
            username: 'monks',
        } as IUser)
    },
    
    {
        amount: 85563,
        user: ({
            username: 'gregsbury',
        } as IUser)
    },
    
    {
        amount: 74815,
        user: ({
            username: 'bevan',
        } as IUser)
    },
    
    {
        amount: 82793,
        user: ({
            username: 'alice5',
        } as IUser)
    },
    
    {
        amount: 12658,
        user: ({
            username: 'digby12345',
        } as IUser)
    },
    
    {
        amount: 9503,
        user: ({
            username: '22bentley',
        } as IUser)
    },
    
    {
        amount: 94889,
        user: ({
            username: 'namby',
        } as IUser)
    },
    
    {
        amount: 80428,
        user: ({
            username: 'July_987',
        } as IUser)
    },
    
    {
        amount: 51689,
        user: ({
            username: 'Pit_bb',
        } as IUser)
    },
    
    {
        amount: 20427,
        user: ({
            username: 'QueenMarry',
        } as IUser)
    },
];

const pickWinners = (max: number) => {
    if (max >= winners.length) {
        return winners;
    }
    const result: IWinner[] = [];
    const selected : number[] = [];
    while (result.length < max) {
        const random = Math.floor(Math.random() * winners.length);
        if (selected.find(a=>a===random) !== undefined) {
            continue;
        }
        selected.push(random);
        result.push(winners[random]);
    }
    return result;
}

export default {
    pickWinners: pickWinners
};