import React, { useState, useEffect } from 'react';
import { makeStyles, CSSProperties } from '@material-ui/styles';
import { ReducersGeneralType } from '../redux/reducers/ReducersGeneral';
import { ReducersGameType } from '../redux/reducers/ReducersGame';
import { PlenikaTheme } from './CustomThemeProvider';
import { ReduxStateType } from '../redux/reducers/Reducers';
import { connect } from 'react-redux';
import { IUser, IWinner, GameStateKeys } from 'plenika-types';
import Tools from '../tools/Tools';
import { Transition } from 'react-transition-group';
import ClassMap from '../types/ClassMap';
import Dummy from '../tools/Dummy';
import { ReactComponent as Star } from '../assests/star.svg';
import { DeviceKeys } from '../tools/Keys';

const MOBILE_LIST_WIDTH = 23;

const useStyles = makeStyles((theme: PlenikaTheme) => ({
    text: {
        color: '#fff3c2',
        fontSize: `${theme.units.h(2)}px`,
        textShadow: `0px ${theme.units.h(2)}px ${theme.units.h(2.2)}px #4C433366`,
    },
    item: {
        margin: theme.units.h(0.5),
        marginLeft: theme.units.h(2),
        paddingLeft: theme.units.h(1),
        paddingRight: theme.units.h(1),
        display: 'inline-block',
    },
    mobileItem: {
        width: `${theme.units.w(MOBILE_LIST_WIDTH)}px`
    },
    title: {
        fontSize: `${theme.units.h(2)}px`,
        textShadow: `0px ${theme.units.h(2)}px ${theme.units.h(2.4)}px #4C433366`,
        color: 'white',
    },
    fontStyle: {
        fontFamily: 'Open Sans',
    },
    bold: {
        fontWeight: 600,
        display: 'inline',
        fontFamily: "'Open Sans', sans-serif",
    },
    titleLarger: {
        fontSize: `${theme.units.h(2.5)}px`,
    },
    gray: {
        color: '#d2cfc5',
    },
    itemSelected: {
        backgroundImage: 'linear-gradient(to right, #fff3c2 0%, #f4f1e6 100%)',
        color: 'black!important',
        borderRadius: `${theme.units.h(1.5)}px`,
    },
    itemFrame: {

    },
    inline: {
        display: 'inline',
        position: 'relative',
    },
    mobileFont: {
        fontSize: `${theme.units.h(2.7)}px!important`,
    },
    border: {
        paddingTop: theme.units.h(0.2),
        height: Math.max(theme.units.h(0.01), 0.5),
        backgroundColor: '#d2cfc5',
    },
    latestWinners: {
        display: 'inline-block',
    },
    mobileFrame: {
        paddingTop: theme.units.h(4),
        textAlign: 'center',
    },
    subMobileFrame: {
        width: theme.units.w(MOBILE_LIST_WIDTH),
        position: 'relative',
    },

    listEntering: {
        top: theme.units.h(-300),
        opacity: 1,
        transition: 'top 18s',
        transitionTimingFunction: 'linear',
        // animation: `$scrollAnim linear infinite`,
    },
    listEntered: {
        top: '-200%',
        opacity: 1,
    },
    listExiting: {
        top: '100%',
        opacity: 0,
    },
    listExited: {
        top: '100%',
        opacity: 0,
    },
    bigBlock: {
        height: theme.units.h(90),
    },

    star1: {
        position: 'absolute',
        zIndex: 1000,
        top: theme.units.h(-1.6),
        left: theme.units.h(-1.5),
        width: theme.units.h(1.7),
        height: theme.units.h(1.7),
    },
    star2: {
        position: 'absolute',
        zIndex: 1000,
        top: theme.units.h(0.5),
        left: theme.units.h(-2.1),
        width: theme.units.h(1.3),
        height: theme.units.h(1.3),
    },
    star3: {
        position: 'absolute',
        zIndex: 1000,
        bottom: theme.units.h(1.5),
        right: theme.units.h(-2),
        width: theme.units.h(1.3),
        height: theme.units.h(1.3),

    },
    star4: {
        position: 'absolute',
        zIndex: 1000,
        bottom: theme.units.h(0),
        right: theme.units.h(-1.2),
        width: theme.units.h(1),
        height: theme.units.h(1),
    },

    '@keyframes appear': {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },
    '@keyframes scrollAnim': {
        '0%': {
            top: '0',
        },
        '100%': {
            top: '-400%',
        },
    },

    anim: {
        position: 'absolute',
        top: 0,
        left: 0,
        animation: `$appear 1s linear`
    },
    scrollAnim: {
        animation: `$scroll 18s linear`
    }
}));

export interface UndoButtonProps {
};


interface StateToPropsType {
    general: ReducersGeneralType;
    game: ReducersGameType;
}

interface DispatchToPropsType {

}

type Props = UndoButtonProps & StateToPropsType & DispatchToPropsType;

const WinnersList: React.FC<Props> = (props) => {
    const classes = useStyles();

    const [data, setData] = useState<IWinner[]>([]);
    const isMobile = props.general.device === DeviceKeys.MOBILE;
    let dummyData = data;
    useEffect(() => {
        setData(Dummy.pickWinners(50));
    }, [props.game.round._id]);

    // sort
    dummyData = dummyData.sort((a, b) => b.amount - a.amount);
    // max 50
    if (dummyData.length > 50) {
        dummyData = dummyData.slice(0, 50);
    }
    // if mobile, let have at least 18
    if (isMobile) {
        while (dummyData.length > 0 && dummyData.length < 18) {
            dummyData = dummyData.concat(dummyData);
        }
    }

    const listTransitionMap: ClassMap = {
        entering: classes.listEntering,
        entered:  classes.listEntered,
        exiting:  classes.listExiting,
        exited:   classes.listExited,
    };
    const transtionMod = ![
        GameStateKeys.RESULTS, 
        GameStateKeys.GAME_ROUND, 
        GameStateKeys.GAME_ROUND_2
    ].includes(props.game.round.current_state.name);

    const stars = (
        <>
            <Star className={classes.star1} />
            <Star className={classes.star2} />
            <Star className={classes.star3} />
            <Star className={classes.star4} />
        </>
    );

    return isMobile ? (
        <div className={classes.mobileFrame}>
            <div style={{ display: 'inline-block' }} className={classes.subMobileFrame}>
                {
                    data.map((winner, i) => {
                        return (
                            <div style={{ animationDelay: `${i}s`, opacity: 0 }} key={"winners_list_mobile_" + i} className={[
                                classes.text,
                                classes.fontStyle,
                                classes.gray,
                                classes.mobileFont,
                                classes.anim,
                                classes.mobileItem,
                            ].join(' ')}>
                                <div className={classes.inline}>
                                    <div className={classes.bold}>
                                        € {Tools.printFunds(winner.amount)}
                                    </div>  {winner.user.username}
                                    {stars}
                                </div>
                            </div>
                        )
                    })
                }
                <div style={{ opacity: 0 }} className={[classes.text, classes.item, classes.fontStyle].join(' ')}>TEMPLATE</div>
                <div className={classes.border} />
                <div className={[classes.latestWinners, classes.mobileFont, classes.gray].join(' ')}>
                    LATEST WINNERS
                </div>
            </div>
        </div>
    ) : (
            <Transition in={transtionMod} timeout={30000}>
                {tState => (
                    <div className={listTransitionMap[tState]} style={{ height: '100%', maxHeight: '100%', position: 'relative' }}>
                        {[0, 1, 2].map((j, i) => (
                            <div key={`key_${j}_${i}`}>
                                <div className={[classes.item, classes.title, classes.fontStyle].join(' ')}>
                                    <div className={classes.inline}>
                                        <div className={[classes.bold, classes.titleLarger].join(' ')}>{dummyData.length}</div> PLAYERS WON <div className={[classes.bold, classes.titleLarger].join(' ')}>€ {Tools.printFunds(dummyData.reduce((s, w) => s + w.amount, 0))}</div>
                                        {stars}
                                    </div>
                                </div>
                                {
                                    dummyData.map((winner, n) => {
                                        const selected = winner.user.username === 'Eli3000';
                                        const gray = n > 7;
                                        return (
                                            <div 
                                                key={`winners_list_data_${j}_${i}_${n}`} 
                                                className={classes.itemFrame}>
                                                <div className={[
                                                    classes.text,
                                                    classes.item,
                                                    classes.fontStyle,
                                                    selected ? classes.itemSelected : '',
                                                    gray ? classes.gray : ''].join(' ')}>
                                                    <div className={classes.bold}>
                                                        € {Tools.printFunds(winner.amount)}
                                                    </div>  {winner.user.username}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className={classes.bigBlock} />
                            </div>
                        ))}
                    </div>
                )}
            </Transition>
        );
}

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        general: { ...state.general },
        game: { ...state.game },
    }
}

const mapDispatchToProps = {};

export default connect<
    StateToPropsType,
    DispatchToPropsType,
    UndoButtonProps,
    ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(WinnersList);
