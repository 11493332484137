import React from 'react';
import Tools from '../../tools/Tools';
import { makeStyles } from '@material-ui/styles';
import { PlenikaTheme } from '../CustomThemeProvider';

const useStyles = makeStyles((theme: PlenikaTheme) => {
    const sumFontSize = theme.general.isMobile ? 4.5 : 2.5;
    const titleFontSize = theme.general.isMobile ? 3 : 1.2;
    const width = theme.general.isMobile ? 11 : 8;
    return {
        frame: {
            color: '#fff3c2',
            display: 'inline-block',
            width: theme.units.w(width),
            paddingLeft: theme.units.w(1),
            paddingRight: theme.units.w(1),
            verticalAlign: 'bottom',
        },
        sumFrame: {
            fontSize: theme.units.h(sumFontSize),
            lineHeight: `${theme.units.h(sumFontSize)}px`,
            padding: theme.units.h(0.2),
            paddingLeft: `${theme.units.h(1)}px!important`,
        },
        titleFrame: {
            fontSize: theme.units.h(titleFontSize),
            lineHeight: `${theme.units.h(titleFontSize)}px`,
            padding: theme.units.h(0.2),
            paddingLeft: `${theme.units.h(1)}px!important`,
        },
        bottomLine: {
            height: theme.units.h(0.3),
        },
        bottomLineFill: {
            backgroundColor: '#fff3c2',
        },
        textStyle: {
            fontFamily: 'open sans',
            fontWeight: theme.general.isMobile?0:'normal',
            fontStretch: 'normal',
        },
        textRight: {
            textAlign: 'end',
        },
    };
});

export interface CurrencyViewProps {
    amount: number;
    title: string;
    selected?: boolean;
    right?: boolean;
}

type Props = CurrencyViewProps;

const CurrencyView: React.FC<Props> = (props) => {
    const classes = useStyles();
    const sum = Tools.printFunds(props.amount);

    return (
        <div className={classes.frame}>
            <div className={[classes.sumFrame,   classes.textStyle, props.right ? classes.textRight: ''].join(' ')}>
                ${sum}
            </div>
            <div className={[classes.titleFrame, classes.textStyle, props.right ? classes.textRight: ''].join(' ')}>
                {props.title}
            </div>
            <div className={classes.bottomLine + (props.selected ? ' '+classes.bottomLineFill : '')}/>
        </div>
    );
} 

export default CurrencyView;
