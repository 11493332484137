import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { PlenikaTheme } from './CustomThemeProvider';
import moment from 'moment';

const useStyles = makeStyles((theme: PlenikaTheme) => {
    const topTextPadding = theme.general.isMobile ? 0.5 : 0.1;
    return {
        textBlock: {
            display: 'inline-block',
            paddingLeft: theme.units.w(topTextPadding),
            paddingRight: theme.units.w(topTextPadding),
        }
    };
});


export interface RoundDetailsProps {
    roundId: string;
    startTime?: number;
}

type Props = RoundDetailsProps;

const RoundDetails: React.FC<Props> = (props) => {
    const classes = useStyles();
    
    const [state, setState] = useState<boolean>();
    useEffect(() => {
        setTimeout(() => {
            setState(!state);
        }, 100);
    });

    let counter = 'COUNTER';
    if (props.startTime) {
        const diff = (new Date().getTime() - props.startTime);
        // const date = new Date(diff);
        /*counter = moment(
            moment(props.startTime).diff(moment(new Date()))
        ).format('hh:mm:ss');*/
        counter = moment(diff).format('hh:mm:ss');
    }

    return (
        <>
            <div className={classes.textBlock}>{props.roundId}</div>
            <div className={classes.textBlock}>{counter}</div>
            <div className={classes.textBlock}>{'CLOCK'}</div>
        </>
    );
} 

export default RoundDetails;
