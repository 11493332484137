import { makeStyles, CSSProperties } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import React, { useState, useEffect } from 'react';
import Chip from "./Chip";
import SyncActions from "../../redux/actions/SyncActions";
import { connect } from "react-redux";
import { ReduxStateType } from "../../redux/reducers/Reducers";
import { Dimensions } from "../../redux/reducers/ReducersGeneral";
import { withContentRect, MeasuredComponentProps } from "react-measure";
import Tools from "../../tools/Tools";
import { PlenikaTheme } from "../CustomThemeProvider";
import { ReducersGameType } from "../../redux/reducers/ReducersGame";
import useGradient, { Colors } from "../hooks/UseGradient";

export interface MobileBettingToolProps {

}

interface MobileBettingToolState {
    open: boolean;
}

interface StateToPropsType {
    dimensions?: Dimensions;
    selectedChip: number;
    canBet: Boolean;
}

interface DispatchToPropsType {
    setSelectedChip: typeof SyncActions.setSelectedChip;
}

type Props = MobileBettingToolProps & StateToPropsType & DispatchToPropsType & MeasuredComponentProps;

const useStyles = makeStyles((theme: PlenikaTheme) => ({
    chipDown: {
        '-webkit-mask-image': 'linear-gradient(to bottom, rgba(0,0,0,1) 40%, transparent 90%)',
    },
    chipUp: {
        
    },
    flexContainer: {
        display: 'flex', 
        alignItems: 'flex-end',
        lineHeight: 0,
        justifyContent: 'center',
    },
    bottomPlatform: {
        backgroundColor: '#FFFFFF77', 
        width: '100%',
    },
    chipLeftDown: {
        width: '60%', 
        height: '60%', 
        margin: '20%', 
        marginTop: '10%',
        marginBottom: '30%',
    },
    chipLeftUp: {
        width: '60%', 
        height: '60%', 
        margin: '20%', 
        marginTop: '30%',
        marginBottom: '10%',
    },
    chipRightDown: {
        width: '60%', 
        height: '60%', 
        margin: '20%', 
        marginTop: '10%',
        marginBottom: '30%',
    },
    chipRightUp: {
        width: '60%', 
        height: '60%', 
        margin: '20%', 
        marginTop: '30%',
        marginBottom: '10%',
    },
    chipTopRight: {
        width: '60%', 
        height: '60%', 
        margin: '20%',
        marginRight: '30%',
        marginLeft: '10%',
    },
    chipTopLeft: {
        width: '60%', 
        height: '60%', 
        margin: '20%', 
        marginRight: '10%',
        marginLeft: '30%',
    },
    chipCenter: {
        width: '75%', 
        height: '75%', 
        margin: '12.5%',
        pointerEvents: 'auto',
        /*'&:before': {
            content: '""',
            position: 'absolute',
            top: '0',
            left: '0',
            boxShadow: `0 0 ${theme.units.h(4)}px ${Colors.GREEN}`, 
            borderRadius: '50%',
            width: '75%',
            height: '75%',
            margin: '12.5%',
        },*/
    },
    chipGlow: {
        position: 'absolute',
        top: '0',
        left: '0',
        boxShadow: `0 0 ${theme.units.h(4)}px ${Colors.GREEN}`, 
        borderRadius: '50%',
        width: '75%',
        height: '75%',
        margin: '12.5%',
    },
    '@keyframes chipShadowKeyframes': {
        '0%': {
            boxShadow: `0 0 ${theme.units.h(4)}px ${Colors.GREEN}`,
        },
        '100%': {
            boxShadow: `0 0 ${theme.units.h(4)}px ${Colors.YELLOW}`,
        }
    },
    glowAnim: {
        animationName: '$chipShadowKeyframes!important',
    },
    chipCenterOpen: {
        '&:before': {
            width: '80%',
            height: '80%',
            margin: '10%',
        }
    },
    closeLocation: {
        top: '33.33%!important',
        left: '33.33%!important',
    },
    closeChip: {
        margin: '20%!important',
    },
    nonClickable: {
        pointerEvents: 'none',
    },
    chipFrame: {
        zIndex: 2000,
        transition: '0.2s'
    },
    outSideArea: {
        width: '300vw',
        height: '300vw',
        top: '-100vh',
        left: '-100vw',
        position: 'fixed',
        zIndex: 1000,
    },
    shadeFrame: {
        position: 'absolute', 
        width: '150%', 
        height: '150%', 
        top: '-25%', 
        left: '-25%',  
    },
    shadeBackround: {
        background: 'radial-gradient(circle, #000F 0%, #0000 75%, #0000 100%)',
    },
}));

const MobileBettingTool: React.FC<Props> = (props) => {
    const classes = useStyles();
    const optionalChips = Tools.getChipNumbers().filter(amount => amount != props.selectedChip);
    const [state, setState] = useState<MobileBettingToolState>({open: false});

    const gradientValues = useGradient();

    useEffect(() => {
        if (!props.canBet) {
            setState({open: false});
        }
    }, [props.canBet]);

    const createHandleChipClick = (amount: number) => {
        return () => {
            if (state.open) {
                props.setSelectedChip(amount);
                setState({open: false});
            } else {
                setState({open: true});
            }
        };
    }; 
    
    const unit = props.contentRect.bounds ? props.contentRect.bounds.width : 0;
    const chipClasses = state.open ? '' : classes.closeChip;
    const chipFrameClasses = (
        classes.chipFrame + ' ' + 
        (state.open ? '' : classes.closeLocation)
    );

    const chipLeftUp = (
        <div 
            onClick={createHandleChipClick(optionalChips[1])} 
            style={{zIndex: 1000000000}}
            className={[chipClasses, classes.chipLeftUp].join(' ')}>
            <Chip amount={optionalChips[1]}/>
        </div>
    );
    const chipLeftDown = (
        <div 
            onClick={createHandleChipClick(optionalChips[0])}
            className={[chipClasses, classes.chipLeftDown].join(' ')}>
            <Chip amount={optionalChips[0]}/>
        </div>
    );
    const chipRightUp = (
        <div 
            onClick={createHandleChipClick(optionalChips[4])}
            className={[chipClasses, classes.chipRightUp].join(' ')}>
            <Chip amount={optionalChips[4]}/>
        </div>
    );
    const chipRightDown = (
        <div 
            onClick={createHandleChipClick(optionalChips[5])}
            className={[chipClasses, classes.chipRightDown].join(' ')}>
            <Chip amount={optionalChips[5]}/>
        </div>
    );
    const chipTopLeft = (
        <div 
            onClick={createHandleChipClick(optionalChips[2])}
            className={[chipClasses, classes.chipTopLeft].join(' ')}>
            <Chip amount={optionalChips[2]}/>
        </div>
    );
    const chipTopRight = (
        <div 
            onClick={createHandleChipClick(optionalChips[3])}
            className={[chipClasses, classes.chipTopRight].join(' ')}>
            <Chip amount={optionalChips[3]}/>
        </div>
    );
    const chipCenter = (
        <div 
            onClick={createHandleChipClick(props.selectedChip)}
            style={{zIndex: 10000}}
            className={[
                classes.chipCenter, 
                state.open ? classes.chipCenterOpen : '',
                // gradientValues.canBet ? classes.chipShadoAnim : '',
                ].join(' ')}>
            <div className={[classes.chipGlow, gradientValues.canBet ? classes.glowAnim : ''].join(' ')} style={gradientValues.style}/>
            <Chip amount={props.selectedChip}/>
        </div>
    );

    const chipFrameDefualt = {width: unit, height: unit, position: 'absolute'} as CSSProperties; 
    const upperFrameStyle: CSSProperties = {
        top: -unit, 
        left: -unit, 
        width: unit*3, 
        height: unit*3, 
        position: 'relative'
    };
    if (!state.open) {
        upperFrameStyle.pointerEvents = 'none';
    }
    return (
        <>
            { state.open ? (
                <div className={classes.outSideArea} onClick={() => setState({open: false})}/>
            ) : null}
            <div ref={props.measureRef} style={{width: '100%', height: unit}}>
                <div style={upperFrameStyle}>
                    <div className={[classes.shadeFrame, (state.open ? classes.shadeBackround : '')].join(' ')}/>
                    <div 
                        className={chipFrameClasses} 
                        style={{top: unit*0, left: unit*0.5, ...chipFrameDefualt}}>
                        {chipTopLeft}
                    </div>
                    <div
                        className={chipFrameClasses} 
                        style={{top: unit*0, left: unit*1.5, ...chipFrameDefualt}}>
                        {chipTopRight}
                    </div>
                    
                    <div
                        className={chipFrameClasses} 
                        style={{top: unit*0.5, left: unit*0, ...chipFrameDefualt}}>
                        {chipLeftUp}
                    </div>
                    <div
                        className={chipFrameClasses} 
                        style={{top: unit*1.5, left: unit*0, ...chipFrameDefualt}}>
                        {chipLeftDown}
                    </div>

                    <div
                        className={chipFrameClasses} 
                        style={{top: unit*0.5, left: unit*2, ...chipFrameDefualt}}>
                        {chipRightUp}
                    </div>
                    <div
                        className={chipFrameClasses} 
                        style={{top: unit*1.5, left: unit*2, ...chipFrameDefualt}}>
                        {chipRightDown}
                    </div>

                    <div
                        className={chipFrameClasses} 
                        style={{top: unit, left: unit,       ...chipFrameDefualt}}>
                        {chipCenter}
                    </div>
                </div>
            </div>
        </>
    )
};

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        dimensions: state.general.dimensions ? state.general.dimensions : undefined,
        selectedChip: state.game.selectedChip,
        canBet: state.game.round.current_state.can_bet,
    }
}

const mapDispatchToProps = { setSelectedChip: SyncActions.setSelectedChip };

export default connect<
StateToPropsType,
DispatchToPropsType,
MobileBettingToolProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(withContentRect('bounds')(MobileBettingTool));