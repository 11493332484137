import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { PlenikaTheme } from '../CustomThemeProvider';
import { ReduxStateType } from '../../redux/reducers/Reducers';
import { connect } from 'react-redux';
import { ReducersGameType } from '../../redux/reducers/ReducersGame';
import NumbersBar from './NumbersBar';


export interface LuckyNumbersProps {

}

interface StateToPropsType {
    game: ReducersGameType;
}

interface DispatchToPropsType {
}


const useStyles = makeStyles((theme: PlenikaTheme) => ({
}));

type Props = LuckyNumbersProps & StateToPropsType & DispatchToPropsType;

const LuckyNumbers: React.FC<Props> = (props) => {
    const classes = useStyles();
    return (
        <NumbersBar
            numberItems={props.game.flatLuckyNumbers.map(item=>({
                number: item.luckyNumber,
                subNumber: item.multi,
            }))}
            lucky={true}
        />
    );
} 

const mapStateToProps: (state: ReduxStateType) => StateToPropsType = (state) => {
    return {
        game: {...state.game},
    }
}

const mapDispatchToProps = { 
};

export default connect<
StateToPropsType,
DispatchToPropsType,
LuckyNumbersProps,
ReduxStateType
>(
    mapStateToProps,
    mapDispatchToProps,
)(LuckyNumbers);